import axios from 'axios'
import {config} from '../config'
export const getActivities = () =>{
    return axios.get(config.getActivites)
    .then(function (response) {
        return response
    })
    .catch(function (error) {
      console.log(error);
    })
}

