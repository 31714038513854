import React,{ useState, useEffect } from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMMONS 
import DescriptionCard from "../../common/descriptionCard/DescriptionCard.common";

// IMPORT PLACEHOLDER IMAGE
import OurWork_Placeholder from "../../../assets/placeholder/OurWork.svg" 
import OurWork_Mobile from "../../../assets/placeholder/OurWork_Mobile.svg" 
import OurWork_Tablet from "../../../assets/placeholder/OurWork_Tablet.svg" 

//IMPORT SERVICES
import {getProducts} from '../../services/products/products';

const ProductCardSection = (props) => {
    const [callApi,setApiCall] = useState(true);
    const [products,setProducts] = useState(null);
    const [animating,setAnimating] = useState(true);
    useEffect(() => {
        if(callApi){
          (async()=> {
            let ourproducts = await getProducts();
            if(ourproducts && ourproducts.data.success){
              setApiCall(false);
              setProducts(ourproducts.data.data);
              setAnimating(false)
            }
          })();
        }
          return () =>{
            setApiCall(false);
          }
      });
    return (
        <CompWrapper style={{marginTop: 100}} title="OUR PRODUCTS" highlightText="PRODUCTS">
            <div className="ourworksection-container">
                <Placeholder placeholderImage={OurWork_Placeholder} placeholderImageMob={OurWork_Mobile} placeholderImageTab={OurWork_Tablet} animating={animating}>
                    <div className="ourworksection">
                    {
                      products &&  products.map((item, index) => (
                        <div
                          key={index}
                          data-aos="fade-up"
                          data-aos-delay={index * 100}
                        >
                          <DescriptionCard 
                            source={item.thumbnail}
                            title={item.product_name}
                            highlightText={item.product_name.split(" ").slice(-1)}
                            description={item.short_desc}
                            onButtonClick={()=>props.history.push(`products/detail/${item.id}-${item.slug_url}`) }
                          />
                        </div>  
                      ))
                    }
                    </div>
                </Placeholder>
            </div>
        </CompWrapper>
    )
}

export default ProductCardSection;