import React, { useState } from "react";
import Slider from "react-slick";

// IMPORT COMMONS
import { DefaultButton } from "../../common/button/Button.common";

// IMPORT PRIVATE COMPONENTS
// FLOATING ELEMENTS
import FloatingElement from "./FloatingElement.component";

// SLIDER IMAGES
import SliderImg1 from "../../../assets/images/slider-images/img1.jpg";
import SliderImg2 from "../../../assets/images/slider-images/img2.jpg";
import SliderImg3 from "../../../assets/images/slider-images/img3.jpg";

// OTHER IMAGES
import ScrollDownLogo from "../../../assets/icons/ScrollDown_teal.svg";
// import FoatingElement from "../../../assets/images/Floating_element_teal.svg";
import FloatingCharacter from "../../../assets/images/Nepali-Girl.png";

// SLIDER IMAGES ARRAY
const SLIDER_IMAGES = [
  SliderImg1,
  SliderImg2,
  SliderImg3
];

const SuccessStories = (props) => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };

  // STATES
  const [mouseXY, setMouseXY] = useState({ x: 0, y: 0 });

  const handleMouseMove = ({ clientX : x, clientY: y }) => {
    setMouseXY({ x, y });
  }

  const { x, y } = mouseXY;
  const floatingCharacterStyles = {
    transform: `translate(${x / 40}px, ${y / 40}px)`
  };

  return (
    <div onMouseMove={handleMouseMove} className="successstories-container">
      <div className="successstories">
        <div className="successtories-slider">
          <Slider {...sliderSettings}>
            {
              SLIDER_IMAGES &&
              SLIDER_IMAGES.length &&
              SLIDER_IMAGES.map((val, index) => (
                <div key={index} className="slider-item">
                  <img alt="ECDC Images" src={val} />
                </div>
              ))
            }
          </Slider>
        </div>

        <div className="slider-overlay"></div>

        <div className="successstories-content-container">
          <div className="successstories-content">
            <div className="successstories-content-left">
              <div className="header-title text-font-rumpel text-xlg">
                SUPPORT OUR <br />
                SUCCESS <span className="text-font-rumpel text-blue">STORIES</span>
              </div>

              <div className="header-desc text-para mt-5 mb-5">
                Every dollar goes towards helping <br /> 
                children realize they can have dreams of <br />
                their own.
              </div>

              <div className="header-button">
                <DefaultButton onClick={()=>props.history.push(`/get-involved`) } style={{background: "transparent", boxShadow: "none"}} title="Get&nbsp;Involved" />
              </div>
            </div>

            <div className="successstories-content-right">
              <div className="floating-image">
                <FloatingElement />
              </div>

              <div style={floatingCharacterStyles} className="floating-character">
                <img alt="FLOATING CHARACTER" src={FloatingCharacter} />
              </div>
            </div>
          </div>

          <div className="scrolldown-icon">
            <img alt="SCROLL DOWN" src={ScrollDownLogo} />
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default SuccessStories;