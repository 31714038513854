import axios from 'axios'
import {config} from '../config'
export const getVideos = () =>{
    return axios.get(config.pushpaVideos)
    .then(function (response) {
        return response
    })
    .catch(function (error) {
      console.log(error);
    })
}


export const getPushpaNews = () =>{
  return axios.get(config.pushpanews)
  .then(function (response) {
      return response
  })
  .catch(function (error) {
    console.log(error);
  })
}
