import React from "react";
import ProductDetailSection from "./productDetailSection/ProductDetailSection.component"

const ProductDetailPage = (props) => {
    window.scrollTo(0,0);
    return (
        <div className="productdetailpage-container">
            <div className="productdetailpage">
                <ProductDetailSection {...props}/>
            </div>
        </div>
    )
}

export default ProductDetailPage;