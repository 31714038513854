import React, { useState, useEffect } from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";

// IMPORT COMPONENTS
import SupportCard from "../../common/supportCard/supportCard.common";

//IMPORT ICONS
// import Donate_teal from "../../../assets/icons/Donate_teal.png";
// import Volunteer_orange from "../../../assets/icons/Volunteer_orange.png";
// import Sponsor_purple from "../../../assets/icons/Sponsor_purple.png";

//SERVICES
import { getWishList } from "../../services/getinvolved/wishlist.service";
const WishListCards = () => {
  const [callApi, setApiCall] = useState(true);
  const [wishlist, setWishlist] = useState(null);
  // const [animating,setAnimating] = useState(true);
  useEffect(() => {
    if (callApi) {
      (async () => {
        let wishlist = await getWishList();
        if (wishlist && wishlist.data.success) {
          setApiCall(false);
          setWishlist(wishlist.data.data);
          // setAnimating(false)
        }
      })();
    }
    return () => {
      setApiCall(false);
    };
  });
  // console.log('data', wishlist)
  return (
    <CompWrapper
      style={{ paddingTop: 0 }}
      title="BUTTERFLY HOME"
      highlightText="HOME"
    >
      <div className="wishlistcards-container">
        <div className="wishlistcards">
          <div>
            <div className="wishlistcards-card">
              {wishlist &&
                wishlist.map((item, index) => {
                  return (
                    <SupportCard
                      key={index}
                      icon={item.image}
                      defaultColor="#3180A9"
                      title={item.title}
                      highlightText={item.title.split(" ").slice(-1)}
                      description={item.description}
                      buttonText={item.price}
                      inverted={true}
                      style={{ width: 80 }}
                      onButtonClick={() =>
                        window.open(
                          "https://utopiafound.kindful.com/?campaign=228328"
                        )
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default WishListCards;
