import React,{ useState, useEffect } from 'react';

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMMONS
// import { DefaultButton } from "../button/Button.common";

// IMPORT COMPONENTS
import AchievementCard from "../achievementCard/AchievementCard.common";

// IMPORT PLACEHOLDER IMAGE
import OurAchievements_Placeholder from "../../../assets/placeholder/OurAchievements.svg"
import OurAchievements_Mobile from "../../../assets/placeholder/OurAchievements_Mobile.svg"
import OurAchievements_Tablet from "../../../assets/placeholder/OurAchievements_Tablet.svg"

// IMPORT ICONS
import Nutrients_orange from "../../../assets/icons/Nutrients_1_orange.png";
import ThingsToBring_green from "../../../assets/icons/ThingsToBring_green.png";
import Fundraiser_purple from "../../../assets/icons/Fundraiser_purple.png";

import Twodash_orange from "../../../assets/icons/Twodash_orange.svg";
import Twodash_green from "../../../assets/icons/Twodash_green.svg";
import Twodash_purple from "../../../assets/icons/Twodash_purple.svg";

//SERVICES
import {getAchievements} from '../../services/common/Achievement.service'

const color = ["#FF8144","#5B9540","#A259F4"];
const icons = [Nutrients_orange,ThingsToBring_green,Fundraiser_purple];
const dashes = [Twodash_orange,Twodash_green,Twodash_purple];

const OurAchievement = () => {
    const [callApi,setApiCall] = useState(true);
    const [achievements,setAchievements] = useState(null);
    const [animating,setAnimating] = useState(true);
    useEffect(() => {
        if(callApi){
          (async()=> {
            let achievement = await getAchievements();
            if(achievement && achievement.data.success){
              setApiCall(false);
              setAchievements(achievement.data.data);
              setAnimating(false)
            }
          })();
        }
          return () =>{
            setApiCall(false);
          }
      });
    return(
        <CompWrapper title="OUR ACHIEVEMENTS" highlightText="ACHIEVEMENTS">
            <div className="ourachievement-container">
                <div className="ourachievement">
                    <div className="ourachievement-desc text-font-averta text-thin">
                    Since the establishment of ECDC , we have been able to help number of women and children and we are not stopping anytime soon.
                    </div>
                    {/* <div className="ourachievement-button">
                        <DefaultButton title="GET INVOLVED" onClick={()=>props.history.push(`/get-involved`) } />
                    </div> */}
                    <Placeholder placeholderImage={OurAchievements_Placeholder} placeholderImageMob={OurAchievements_Mobile} placeholderImageTab={OurAchievements_Tablet} animating={animating} style={{width:'70%'}}>
                        <div className="ourachievement-card">
                            {achievements && achievements.map((item,index) =>{
                                return(
                                    <div 
                                        key = {index}
                                        data-aos="fade-up"
                                        data-aos-delay={index * 100}
                                    >
                                        <AchievementCard
                                            highlightText={item.programme}
                                            normalText={item.desc}
                                            buttonText={`${item.total}+`}
                                            icon={icons[item.image-1]}
                                            defaultColor={color[item.image-1]}
                                            dash={dashes[item.image-1]}
                                        />
                                     </div>
                                )
                            })}
                        </div>
                    </Placeholder>
                </div>
            </div>
        </CompWrapper>
    )
}

export default OurAchievement;