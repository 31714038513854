import React, { useState, useEffect } from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMMONS
import DescriptionCard from "../../common/descriptionCard/DescriptionCard.common";

// IMPORT PLACEHOLDER IMAGE
import OurWorkCategory_Placeholder from "../../../assets/placeholder/OurWorkCategory.svg";
import OurWorkCategory_Mobile from "../../../assets/placeholder/OurWorkCategory_Mobile.svg";
import OurWorkCategory_Tablet from "../../../assets/placeholder/OurWorkCategory_Tablet.svg";

//IMPORT SERVICES
import { getworkCategory } from "../../services/ourwork/category";

const OurWorkCategory = (props) => {
  window.scrollTo(0, 0);
  const [callApi, setApiCall] = useState(true);
  const [works, setWorks] = useState(null);
  const [project_details, setProject_details] = useState();
  const [animating, setAnimating] = useState(true);
  useEffect(() => {
    let id = props.match.params.id || 1;
    if (callApi) {
      (async () => {
        let ourworks = await getworkCategory(id);
        if (ourworks && ourworks.data.success) {
          setApiCall(false);
          setWorks(Object.entries(ourworks.data.data));
          setProject_details(ourworks.data.project_detail[0]);
          setAnimating(false);
        }
      })();
    }
    return () => {
      setApiCall(false);
    };
  });
  // console.log('Logs', works)
  return (
    <div className="ourworkcategory-container">
      <div className="ourworkcategory">
        <Placeholder
          style={{ marginTop: 200, marginLeft: "7.5%", marginRight: "7.5%" }}
          placeholderImage={OurWorkCategory_Placeholder}
          placeholderImageMob={OurWorkCategory_Mobile}
          placeholderImageTab={OurWorkCategory_Tablet}
          animating={animating}
        >
          <CompWrapper
            style={{ marginTop: 100 }}
            title={project_details && project_details.title}
            highlightText={
              project_details && project_details.title.split(" ").slice(-1)
            }
          >
            <div className="ourworkcategory-desc text-font-averta text-thin mt-5">
              {project_details && project_details.description}
            </div>
          </CompWrapper>
          <div className="ourworkcategory-cat">
            {works &&
              works.map((item, index) => {
                return (
                  <CompWrapper
                    key={index}
                    style={{ marginTop: -100 }}
                    title={item[0]}
                    highlightText=""
                  >
                    <div key={index} className="ourworkcategory-cat-section">
                      <div className="ourworkcategory-cat-section-content">
                        {item &&
                          item[1].map(
                            (val, index) =>
                              val.title && (
                                <DescriptionCard
                                  source={val.thumbnail}
                                  title={val.title.replace(
                                    /^(.{40}[^\s]*).*/,
                                    "$1"
                                  )}
                                  highlightText={val.title
                                    .replace(/^(.{40}[^\s]*).*/, "$1")
                                    .split(" ")
                                    .slice(-1)}
                                  description={val.description}
                                  // eslint-disable-next-line react/prop-types
                                  onButtonClick={() =>
                                    props.history.push(
                                      `/our-work/category/${val.id}-${val.slug_url}`
                                    )
                                  }
                                  key={index}
                                />
                              )
                          )}
                      </div>
                    </div>
                  </CompWrapper>
                );
              })}
          </div>
        </Placeholder>
      </div>
    </div>
  );
};

export default OurWorkCategory;
