import React from "react";



// IMPORT COMMON
import { OverlappedCardInverted } from "../../common/overlappedCard/OverlappedCard.common";

// IMPORT IMAGE
import OURSTAFF from "../../../assets/images/OurStaff.png";



const OurStaffs = () => {
    return (
        <div className="ourstaffs-container">
            <div className="ourstaffs">
                    <OverlappedCardInverted image={OURSTAFF} description="Many of them have been with us since the beginning, and most for at least a decade.  Because we are a family, we all look after each other, and we all wear many hats. In addition to our president and administrative team, ECDC would not be possible without the devotion of its live-in Aunties and other on-site staff."/>
            </div>
        </div>
    )
}

export default OurStaffs;