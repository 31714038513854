import React, { useEffect } from 'react';
import anime from 'animejs';

// IMPORT ICONS
import BalloonIcon from '../../../assets/icons/Balloon_green.png';

// STYLES
const getStyles = () => ({
  container: {
    position: 'absolute',
    top: 100,
  },
  imgContainer: {
    width: 60,
    position: 'absolute',
    left: -10,
    top: -70,
  },
  img: {
    width: '100%',
  },
  greenLineSvg: {
    width: '100%',
    height: 450,
  },
});

const Balloon = () => {
  const styles = getStyles();

  const path_one =
    'M258.612,12380s13.052,45.045,18.365,78.244-1.591,72.931-4.745,100.067c-4.159,35.781-11.076,72.725-13.62,89.261-4.44,28.858-7.913,82.094,0,159.829s0,328.537,0,328.537';
  const path_two =
    'M245.65,12379.122s-.794,38.247,0,80.592,10.3,79.089,7.15,106.226c-4.159,35.781-8.6,61.806-7.15,122.171s17.929,136.1,25.842,213.83-12.881,234-12.881,234';

  const getGreenLine = () => {
    return (
      <svg
        style={styles.greenLineSvg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 841.312"
      >
        <path
          className="Green_path"
          id="Green_pathone"
          d={path_one}
          transform="translate(-220.217 -12379.305)"
          fill="none"
          stroke="#5b9540"
          strokeWidth={5}
        />
      </svg>
    );
  };

  useEffect(() => {
    const timeline = anime.timeline({
      targets: '.Green_path',
      easing: 'easeInOutQuad',
      duration: 2000,
      loop: true,
      direction: 'alternate',
    });

    timeline.add({
      d: { value: path_two },
    });

    const timelineBalloon = anime.timeline({
      targets: '.balloon-img',
      easing: 'easeInOutQuad',
      duration: 2000,
      loop: true,
      direction: 'alternate',
    });

    timelineBalloon.add({
      rotate: 20,
      translateY: 20,
      translateX: 10,
    });
  }, []);

  return (
    <div style={styles.container}>
      <div className="balloon-img" style={styles.imgContainer}>
        <img style={styles.img} alt="BALLOON" src={BalloonIcon} />
      </div>

      <div style={styles.orangeLineContainer}>{getGreenLine()}</div>
    </div>
  );
};

export default Balloon;
