import React from "react";
import PropTypes from "prop-types";

// IMPORT IMAGES
import NoImageAvailable from "../../../assets/images/no_img_available.jpg";
import ECDC_twodash from "../../../assets/icons/ECDC_twodash.svg";

// IMPORT COMMONS 
import { DefaultButton } from "../../common/button/Button.common";
import { SemiCircleStories } from "../../common/floatingImage/Circle.common"
// OVERLAPPED CARD LEFT
const OverlappedStoriesCard = ({ image, title, highlightText, description, onButtonClick, date }) =>{

    if(title.indexOf(highlightText) < 0) {
        console.error("NO HIGHLIGHT PART");
      }
    
      let titleWithoutHighlight = title.slice(0, title.indexOf(highlightText));
      let titleWithHighlight = title.slice(title.indexOf(highlightText), title.length);

    return(
        <div className="overlappedstoriescard-container">
            <div className="overlappedstoriescard">
                <SemiCircleStories/>
                <div className="overlappedstoriescard-right">
                    <div className="overlappedstoriescard-right-title text-font-rumpel text-md">
                        { titleWithoutHighlight } <span className="text-blue text-font-rumpel">{ titleWithHighlight }</span>
                    </div>
                    <div className="overlappedstoriescard-right-desc text-black text-font-averta text-xthin">
                        {description}
                    </div>
                        <div className="overlappedstoriescard-right-button">
                            <DefaultButton onClick={onButtonClick} style = {{paddingLeft: 30, paddingRight: 30}} title="LEARN MORE" />
                        </div>
                    <div className="overlappedstoriescard-right-twodash">
                        <img alt="FLOATING TWO DASH" src={ECDC_twodash} />
                    </div>
                </div>
                <div className="overlappedstoriescard-left" style={{backgroundImage: `url(${image})`}}>
                    <div className="overlappedstoriescard-left-float">
                        {date}
                    </div>
                </div>
            </div>
        </div>
    )
}

OverlappedStoriesCard.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    highlightText: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func,
    date: PropTypes.bool
};
  
OverlappedStoriesCard.defaultProps = {
    image: NoImageAvailable,
    title: "Default Title",
    highlightText: "Title",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    onButtonClick: () => false
};

export default OverlappedStoriesCard;