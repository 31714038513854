import React, { Component } from "react";
import Slider from "react-slick";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMPONENTS
import PressCard from "../pressCard/PressCard.component";

// IMPORT PLACEHOLDER IMAGE
import Press_Placeholder from "../../../assets/placeholder/Press.svg";
import Press_Mobile from "../../../assets/placeholder/Press_Mobile.svg";
import Press_Tablet from "../../../assets/placeholder/Press_Tablet.svg";

//Services
import { getMedia } from "../../services/aboutus/press";
class PressAndMedia extends Component {
  constructor() {
    super();

    this.state = {
      width: 1920,
      media: null,
      animating: true,
    };

    window.addEventListener("resize", this.widthUpdate);
  }

  componentDidMount() {
    this.getMedia();
    this.widthUpdate();
  }

  getMedia = async () => {
    let media = await getMedia();
    if (media && media.data.success) {
      this.setState({
        media: media.data.data,
        animating: false,
      });
    }
  };

  widthUpdate = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  render() {
    const sliderSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      slidesToShow:
        this.state.width <= 1200 ? (this.state.width <= 576 ? 1 : 2) : 4,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
    };
    return (
      <CompWrapper
        style={{ marginTop: 100 }}
        title="PRESS &amp; MEDIA"
        highlightText="MEDIA"
      >
        <div className="pressandmedia-container">
          <Placeholder
            placeholderImage={Press_Placeholder}
            placeholderImageMob={Press_Mobile}
            placeholderImageTab={Press_Tablet}
            animating={this.state.animating}
          >
            <Slider {...sliderSettings}>
              {this.state.media &&
                this.state.media.map((item, index) => {
                  return (
                    <PressCard
                      key={index}
                      title={item.media_name}
                      highlightText={item.media_name.split(" ").slice(-1)}
                      description={item.title}
                      icon={item.image}
                      url={item.url}
                    />
                  );
                })}
            </Slider>
          </Placeholder>
        </div>
      </CompWrapper>
    );
  }
}

export default PressAndMedia;
