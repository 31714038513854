import React from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";


// IMPORT COMPONENTS
import SupportCard from "../../common/supportCard/supportCard.common";

//IMPORT ICONS
import Donate_teal from "../../../assets/icons/Donate_teal.png"; 
import Volunteer_orange from "../../../assets/icons/Volunteer_orange.png"; 
import Sponsor_purple from "../../../assets/icons/Sponsor_purple.png"; 

const SupportUs = (props) => {
    return (
        <CompWrapper style={{marginTop: 100}} title="SUPPORT US" highlightText="US">
            <div className="supportus-container">
                <div className="supportus">
                    <div className="supportus-desc text-font-averta text-thin">
                        Your support will help in bringing the change in lives of children.
                    </div>
                    <div className="supportus-card">
                        <div
                            data-aos="fade-up"
                        >
                            <SupportCard 
                                icon={Donate_teal} 
                                defaultColor="#3180A9" 
                                title="DONATE NOW" 
                                highlightText="NOW" 
                                description="Your support will help in bringing the change in lives of children. Big or small, your contribution will impact in the lives of children." 
                                buttonText="DONATE NOW" 
                                onButtonClick={() => window.open("https://utopiafound.kindful.com/?campaign=228328")}
                                inverted={false}
                                style={{width: 100}} 
                            />
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <SupportCard 
                                icon={Volunteer_orange} 
                                defaultColor="#FF8144" 
                                title="VOLUNTEER" 
                                highlightText="VOLUNTEER" 
                                description="We seek enthusiast and committed volunteers who are willing to create a better place for the children. Join us, together we can make changes!" 
                                buttonText="VOLUNTEER" 
                                onButtonClick={()=>props.history.push(`/get-involved/volunteer`) }
                                inverted={false}
                                style={{width: 35}} 
                            />
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <SupportCard 
                                icon={Sponsor_purple} 
                                defaultColor="#A259F4" 
                                title="BECOME A SPONSOR" 
                                highlightText="SPONSOR" 
                                description="At Children's home home, we encourage and emphasize on building the relationship, by which you can personally make a difference in individual's life." 
                                buttonText="SPONSOR" 
                                onButtonClick={()=>props.history.push(`/get-involved/become-a-sponsor`) }
                                inverted={false}
                                style={{width: 60}} 
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        </CompWrapper>
    )
}

export default SupportUs;