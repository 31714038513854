import React,{ useState, useEffect } from 'react';

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMMONS
import DescriptionCard from "../../common/descriptionCard/DescriptionCard.common";

// IMPORT PLACEHOLDER IMAGE
import OurWork_Placeholder from "../../../assets/placeholder/OurWork.svg" 
import OurWork_Mobile from "../../../assets/placeholder/OurWork_Mobile.svg" 
import OurWork_Tablet from "../../../assets/placeholder/OurWork_Tablet.svg" 

//IMPORT SERVICES
import {getwork} from '../../services/homePage/ourwork.service'
const OurWork = (props) => {
  const [callApi,setApiCall] = useState(true);
  const [works,setWorks] = useState(null);
  const [animating,setAnimating] = useState(true);
  useEffect(() => {
    if(callApi){
      (async()=> {
        let ourworks = await getwork();
        if(ourworks && ourworks.data.success){
          setApiCall(false);
          setWorks(ourworks.data.data);
          setAnimating(false)
        }
      })();
    }
      return () =>{
        setApiCall(false);
      }
  });
    return (
      <CompWrapper title="OUR WORK" highlightText="WORK" seeAllButtonVisible={true} onSeeAllClick={()=>props.history.push(`/our-work`) }>
        <div className="ourwork-container">
          <Placeholder placeholderImage={OurWork_Placeholder} placeholderImageMob={OurWork_Mobile} placeholderImageTab={OurWork_Tablet} animating={animating}>
            <div className="ourwork">
              {
               works &&  works.slice(0, 3).map((item, index) => (
                 <div data-aos="fade-up" data-aos-delay={index * 100} key={index}>
                    <DescriptionCard 
                      source={item.image}
                      title={item.title}
                      highlightText={item.title.split(" ").slice(-1)}
                      description={item.description}
                      onButtonClick={()=>props.history.push(`/our-work/${item.id}-${item.slug_url}`) }
                     />
                  </div>
                ))
              }
            </div>
          </Placeholder>
        </div>
      </CompWrapper>
    )
}

export default OurWork;