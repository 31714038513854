import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import { postSubscriber } from "../../services/getinvolved/volunteers.service";

// IMPORT COMMONS
import FooterKite from "./FooterKite.common";
import Ballon from "./Balloon.common";

// IMPORT ICONS
import SendWhite from "../../../assets/icons/Send_white.png";
import FacebookIcon from "../../../assets/icons/Facebook.png";
import InstagramIcon from "../../../assets/icons/Instagram.png";
import TwitterIcon from "../../../assets/icons/Twitter.png";
import YoutubeIcon from "../../../assets/icons/Youtube.png";
import CodnivIcon from "../../../assets/icons/Codniv_logo.png";

// FOOTER LINKS
const FOOTER_LINKS = {
  QUICK_LINKS: [
    { title: "Home", redirect: "/" },
    // {title: "Our  Products",redirect:"/products"},
    { title: "Contact Us", redirect: "/contact-us" },
    { title: "Stories", redirect: "/stories" },
  ],
  OUR_WORKS: [
    { title: "Residental Home", redirect: "/our-work/4-residential-home" },
    {
      title: "Prison Outreach Projects",
      redirect: "/our-work/3-prison-outreach-projects",
    },
    {
      title: "Young Adult Support",
      redirect: "/our-work/2-young-adult-support",
    },
  ],
  GET_INVOLVED: [
    // {title: "Donate"},
    { title: "Volunteer", redirect: "/get-involved/volunteer" },
    { title: "Become a Sponsor", redirect: "/get-involved/become-a-sponsor" },
    { title: "Whishlist", redirect: "/get-involved/wishlist" },
    { title: "Items to bring", redirect: "/get-involved/items-to-bring" },
  ],
};

const Footer = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [suberror, setSuberror] = useState(false);
  const [loading, setloading] = useState(false);

  const onSubmit = async (data) => {
    setSuberror(false);
    setloading(true);
    let subscribeResponse = await postSubscriber(data);
    if (subscribeResponse.data.success === true) {
      toast.info("Succcess! Thanks for Subscribing.");
      setloading(false);
    } else {
      toast.error("Failed! Please recheck your details or Try again Later");
      setloading(false);
    }
  };
  return (
    <div className="footer-wrapper">
      <div className="footer-kite">
        <FooterKite />
      </div>

      <div className="footer-balloon">
        <Ballon />
      </div>

      <div className="footer-container">
        <div className="footer">
          <form method="post" encType="multipart/form-data">
            <div className="footer-newsletter">
              <div className="footer-newsletter-title text-md text-font-rumpel text-blue">
                NEWSLETTER
              </div>

              <div className="footer-newsletter-desc text-small">
                Subscribe us to be notified about our
                <br />
                stories, activities.
              </div>

              <div className="footer-newsletter-email">
                <input
                  placeholder="YOUR EMAIL ADDRESS"
                  className={`newsletter-input ${
                    suberror === true || errors.email ? "showerror" : ""
                  }`}
                  name="email"
                  ref={register({ required: true })}
                  required
                />

                <div
                  className="newsletter-submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  <div className="newsletter-submit-text text-white">
                    {loading ? "PROCESSING..." : "SUBSCRIBE"}
                  </div>
                  <div className="newsletter-submit-image">
                    <img alt="Submit Newsletter" src={SendWhite} />
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="footer-links">
            <div className="footer-links-block first">
              <div className="footer-title">ECDC.</div>

              <div className="footer-desc mt-5 mb-5">
                No child should grow <br />
                up behind prison <br />
                bars.
              </div>

              <div className="footer-icons">
                <div
                  className="footer-icons-image mr-4"
                  onClick={() =>
                    window.open("https://www.facebook.com/ecdcnepal/")
                  }
                >
                  <img alt="Facebook" src={FacebookIcon} />
                </div>
                <div
                  className="footer-icons-image mr-4"
                  onClick={() =>
                    window.open("https://www.instagram.com/ecdcnepal/")
                  }
                >
                  <img alt="Instagram" src={InstagramIcon} />
                </div>
                <div
                  className="footer-icons-image mr-4"
                  onClick={() =>
                    window.open("https://twitter.com/inmateschildren")
                  }
                >
                  <img alt="Twitter" src={TwitterIcon} />
                </div>
                <div
                  className="footer-icons-image"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCJv9TvZ3ody0zZlbnnwkhcg"
                    )
                  }
                >
                  <img alt="YouTube" src={YoutubeIcon} />
                </div>
              </div>
            </div>

            <div className="footer-links-block">
              <div>
                <div className="footer-links-block-title">QUICK LINKS</div>
                <ul className="footer-links-block-links">
                  {FOOTER_LINKS["QUICK_LINKS"].map(
                    ({ title, redirect }, index) => (
                      <li
                        key={index}
                        className="links-item"
                        onClick={() => props.history.push(redirect)}
                      >
                        {title}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            <div className="footer-links-block">
              <div>
                <div className="footer-links-block-title">OUR WORKS</div>
                <ul className="footer-links-block-links">
                  {FOOTER_LINKS["OUR_WORKS"].map(
                    ({ title, redirect }, index) => (
                      <li
                        key={index}
                        className="links-item"
                        onClick={() => window.open(redirect, "_self")}
                      >
                        {title}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            <div className="footer-links-block">
              <div>
                <div className="footer-links-block-title">GET INVOLVED</div>
                <ul className="footer-links-block-links">
                  {FOOTER_LINKS["GET_INVOLVED"].map(
                    ({ title, redirect }, index) => (
                      <li
                        key={index}
                        className="links-item"
                        onClick={() => props.history.push(redirect)}
                      >
                        {title}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footerinfo-container">
          <div className="footerinfo">
            <div className="footerinfo-left">&copy; ECDC | Copyright 2020</div>

            <div className="footerinfo-left text-center">
              pushpabasnet@ecdcnepal.org
              <br />
              info@ecdcnepal.org
            </div>

            <div
              className="footerinfo-right"
              onClick={() => window.open("https://codniv.com/")}
            >
              <span>Powered by</span>
              <img
                alt="Codniv Innovation Pvt. Ltd."
                title="Visit Codniv.com"
                src={CodnivIcon}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default withRouter(Footer);
