import React from "react";
import PropTypes from "prop-types";

// IMPORT COMMONS
import { DefaultButton } from "../button/Button.common";

// IMPORT IMAGES
import NoImageAvailable from "../../../assets/images/no_img_available.jpg";

// IMPORT LOGOS
import TwoDash from "../../../assets/icons/ECDC_twodash.svg";

const DescriptionCard = ({ source, title, highlightText, description, onButtonClick, onClick, date }) => {
  
  if(title.indexOf(highlightText) < 0) {
    console.error("NO HIGHLIGHT PART");
  }

  let titleWithoutHighlight = title.slice(0, title.indexOf(highlightText));
  let titleWithHighlight = title.slice(title.indexOf(highlightText), title.length);
  
  return (
    <div className="descriptioncard-container" onClick={onClick}>
      <div className="descriptioncard">
        <div className="descriptioncard-image" style={{backgroundImage: `url(${source})`}}>
          {
            date &&
            <div className="descriptioncard-date">
              { date }
            </div>
          }
        </div>
        <div className="descriptioncard-desc">
          <div className="desc-title mt-2 mb-4 text-font-rumpel">
            { titleWithoutHighlight } <span className="text-blue text-font-rumpel">{ titleWithHighlight }</span>
          </div>

          <div className="desc-text text-xthin">
          {description.slice(0,80)}
          </div>
          <div className="desc-button">
            <DefaultButton style={{paddingLeft: 30, paddingRight: 30}} onClick={onButtonClick} title="Learn More" />
          </div>
        </div>

        <div className="descriptioncard-twodash">
          <img alt="FLOATING TWO DASH" src={TwoDash} />
        </div>
      </div>
    </div>
  )
}

DescriptionCard.propTypes = {
  source: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  highlightText: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  description: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  date: PropTypes.node
};

DescriptionCard.defaultProps = {
  source: NoImageAvailable,
  title: "Default Title",
  highlightText: "Title",
  description: "Default Description",
  onClick: () => false
};

export default DescriptionCard;