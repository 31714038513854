import React from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
// import NecessaryItems from "../necessaryItems/necessaryItems.component"
const ItemsToBring = () => {
    return (
        <CompWrapper style={{marginTop: 100}} title="ITEMS TO BRING" highlightText="BRING">
            <div className="itemstobring-container">
                <div className="itemstobring">
                    <div className="itemstobring-desc text-font-averta text-thin">
                    One of the best ways to get involved with ECDC is to become a sponsor. We take sponsorship donations seriously, which means the causes that you request to sponsor really are effected directly by your donation.  With our Sponsor A Child program, you are connected to a specific child for a year, to share in their progress that is made possible by you!
                    </div>
                </div>
            </div>
        </CompWrapper>
        )
    }
export default ItemsToBring;