import React from "react"

export const OverlappedCircle = () => {
    return (
        <div className="overlappedcircle-container">
            <div className="overlappedcircle">
                <svg width="213" height="315" viewBox="0 0 318 470">
                    <g id="Group_298" data-name="Group 298" transform="translate(-1602.001 -5062.001)">
                        <path id="Subtraction_2" data-name="Subtraction 2" d="M11427,10791a236.76,236.76,0,0,1-47.361-4.774,233.745,233.745,0,0,1-84.031-35.36,235.687,235.687,0,0,1-85.141-103.394,233.8,233.8,0,0,1-13.692-44.111,237.285,237.285,0,0,1,0-94.721,233.7,233.7,0,0,1,35.359-84.031,235.668,235.668,0,0,1,103.394-85.142,233.817,233.817,0,0,1,44.111-13.692A236.752,236.752,0,0,1,11427,10321a234.1,234.1,0,0,1,83,15.078v439.844A234.059,234.059,0,0,1,11427,10791Z" transform="translate(-9590 -5259)" fill="#3180a9" opacity="0.07" />
                        <path id="Subtraction_3" data-name="Subtraction 3" d="M11250,10440a58,58,0,1,1,15-114.039v112.08A58.192,58.192,0,0,1,11250,10440Z" transform="translate(-9345 -5179)" fill="#3180a9" opacity="0.07" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export const WhiteCircle = () => {
    return (
        <div className="whitecircle-container">
            <div className="whitecircle">
                <svg width="180" height="250" viewBox="0 0 230.998 320.004">
                    <path id="Subtraction_7" data-name="Subtraction 7" d="M11465,17901a197.543,197.543,0,0,1-39.5-3.979,195.13,195.13,0,0,1-70.084-29.494,196.553,196.553,0,0,1-71.01-86.234,195.039,195.039,0,0,1-11.42-36.795,197.8,197.8,0,0,1,0-79,194.979,194.979,0,0,1,29.489-70.084c3.337-4.936,6.949-9.787,10.735-14.416H11500v316.887A197.723,197.723,0,0,1,11465,17901Z" transform="translate(-11269 -17580.998)" fill="rgba(255,255,255,0.08)"/>
                </svg>
            </div>
        </div>
    )
}

export const SemiCircle = () => {
    return (
        <div className="semicircle-container">
            <div className="semicircle" >
                <svg width="74" height="130" viewBox="0 0 152 263.999">
                    <path id="Intersection_1" data-name="Intersection 1" d="M-3160.494-6021a133.017,133.017,0,0,1-1.506-20,132,132,0,0,1,132-132,132,132,0,0,1,132,132,133.049,133.049,0,0,1-1.505,20Z" transform="translate(6173 -2898.001) rotate(-90)" fill="#3180a9" opacity="0.08" />
                </svg>
            </div>
        </div>
    )
}

export const SemiCircleStories = () => {
    return (
        <div className="semicirclestories-container">
            <div className="semicirclestories">
                <svg width="74" height="130" viewBox="0 0 152 263.999">
                    <path id="Intersection_1" data-name="Intersection 1" d="M-3160.494-6021a133.017,133.017,0,0,1-1.506-20,132,132,0,0,1,132-132,132,132,0,0,1,132,132,133.049,133.049,0,0,1-1.505,20Z" transform="translate(6173 -2898.001) rotate(-90)" fill="#3180a9" opacity="0.08" />
                </svg>
            </div>
        </div>
    )
}

export const SubCircle = () => {
    return (
        <div className="subcircle-container">
            <div className="subcircle" >
                <svg width="213" height="300" viewBox="0 0 334.057 470.117">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M1823.672,17977.873h-.013a234.312,234.312,0,0,1-98.845-21.924v-426.3a237.155,237.155,0,0,1,99.151-21.889,234.771,234.771,0,0,1,226.841,174.236,236.7,236.7,0,0,1,7.647,46.982,233.711,233.711,0,0,1-12.406,90.318,235.709,235.709,0,0,1-77.833,109,233.533,233.533,0,0,1-39.065,24.643,236.842,236.842,0,0,1-105.477,24.936Z" transform="translate(-1724.813 -17507.756)" fill="#3180a9" opacity="0.07" />
                </svg>
            </div>
        </div>
    )
}

export const SubCircleVolunteer = () => {
    return (
        <div className="subcirclevolunteer-container" >
            <div className="subcirclevolunteer">
                <svg width="213" height="300" viewBox="0 0 334.057 470.117">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M1823.672,17977.873h-.013a234.312,234.312,0,0,1-98.845-21.924v-426.3a237.155,237.155,0,0,1,99.151-21.889,234.771,234.771,0,0,1,226.841,174.236,236.7,236.7,0,0,1,7.647,46.982,233.711,233.711,0,0,1-12.406,90.318,235.709,235.709,0,0,1-77.833,109,233.533,233.533,0,0,1-39.065,24.643,236.842,236.842,0,0,1-105.477,24.936Z" transform="translate(-1724.813 -17507.756)" fill="#3180a9" opacity="0.07" />
                </svg>
            </div>
        </div>
    )
}

export const Circle = () => {
    return (
        <div className="circle-container">
            <div className="circle" >
                <svg width="100" height="100" viewBox="0 0 575.63 575.63">
                    <circle id="Ellipse_95" data-name="Ellipse 95" cx="235" cy="235" r="235" transform="matrix(0.259, 0.966, -0.966, 0.259, 453.985, 0)" fill="#3180a9" opacity="0.07" />
                </svg>
            </div>
        </div>
    )
}

export const SemiCircleDetail = () => {
    return (
        <div className="semicircledetail-container">
            <div className="semicircledetail" >
                <svg width="69" height="120" viewBox="0 0 152 263.999">
                    <path id="Intersection_1" data-name="Intersection 1" d="M-3160.494-6021a133.017,133.017,0,0,1-1.506-20,132,132,0,0,1,132-132,132,132,0,0,1,132,132,133.049,133.049,0,0,1-1.505,20Z" transform="translate(6173 -2898.001) rotate(-90)" fill="#3180a9" opacity="0.08" />
                </svg>
            </div>
        </div>
    )
}