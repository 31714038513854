import React, { useEffect } from "react";
import anime from "animejs";

// IMPORT ICONS
import KiteIcon from "../../../assets/icons/Kite_orange.png";

// STYLES
const getStyles = () => ({
  container: {
    position: "absolute",
    top: 100
  },
  imgContainer: {
    width: 60,
    position: "absolute",
    left: 290,
    top: -55,
    transform: `rotate(60deg)`
  },
  img: {
    width: "100%"
  },
  orangeLineContainer: {},
  orangeLineSvg: {
    width: "100%"
  }
});

const FooterKite = () => {

  const styles = getStyles();

  const path_one = "M1680.257,12280.5s-135.633-34.605-101,155.856-23.907,289.163-134.584,438.3-237.685,211.008-312.715,239.865";
  const path_two = "M1597.869,12305.707s-117.731,19.207-129.337,197.939,87.465,252.437-23.212,401.571-238.334,180.444-313.365,209.3";

  const getOrangeLine = () => {
    return (
      <svg style={styles.orangeLineSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 549.818 841.312">
        <path className="Orange_path" id="Orange_path" d={path_one} transform="translate(-1131.058 -12275.541)" fill="none" stroke="#ff8144" strokeWidth={5} />
      </svg>
    )
  }
  
  useEffect(() => {
    const timeline = anime.timeline({
      targets: ".Orange_path",
      easing: "easeInOutQuad",
      duration: 4000,
      loop: true,
      direction: "alternate"
    });

    timeline.add({
      d: { value: path_two }
    });
  
    const timelineKite = anime.timeline({
      targets: ".kite-img",
      easing: "easeInOutQuad",
      duration: 4000,
      loop: true,
      direction: "alternate"
    });

    timelineKite.add({
      rotate: 50,
      translateX: -20,
      translateY: 40
    });
  }, [])

  return (
    <div style={styles.container}>
      <div className="kite-img" style={styles.imgContainer}>
        <img style={styles.img} alt="KITE" src={ KiteIcon } />
      </div>

      <div style={styles.orangeLineContainer}>
        {
          getOrangeLine()
        }
      </div>
    </div>
  )
}

export default FooterKite;