import React from "react";

// IMPORT COMMONS
import OurAchievement from "../common/ourAchievement/OurAchievement.component";


// IMPORT COMPONENTS
import DetailCardSection from "./detailCardSection/DetailCardSection.component";
import Stories from "../homePage/stories/Stories.component";
import {OverlappedCircle} from "../common/floatingImage/Circle.common";


const DetailPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
          <DetailCardSection {...props} />
          <Stories {...props} />
          <OurAchievement {...props} />
          <OverlappedCircle/>
        </React.Fragment>
    )
}

export default DetailPage;