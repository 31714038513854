import React from "react";
// import PropTypes from "prop-types";

// IMPORT COMPONENTS

// IMPORT LOGOS


const VolunteerDescription = () =>{
    return(
        <div className="volunteerdescription-container">
            <div className="volunteerdescription">
                <p className="text-para">
                ECDC is supported by many volunteers who are a core part of our team and we always welcome anyone who truly want to make an impact on a child’s life. At ECDC, we encourage volunteer to enrich the children’s knowledge and education on diverse field.
                </p>
                <p className="text-para mt-3">
                The volunteers could contribute towards the academics (languages, creative writing, science, culture and natural history). Likewise, the volunteers are encouraged to build the team spirit and leadership using the games and sports, which also helps in strengthening the logics and concentration among the children.
                </p>
                <p className="text-para mt-3">
                Depending on the interest of the children, volunteers can help children in developing arts and crafts, such as magazines for collage making, beads for jewelry making. This may also include sharing the cultures of volunteers such as native recipes, dance and music.
                languages, arts and crafts, sports.
                </p>

            </div>
        </div>
    )}

export default VolunteerDescription
