import React from 'react';
import PropTypes from "prop-types";

const Placeholder = ({ placeholderImage, placeholderImageMob, placeholderImageTab, children, animating, style }) => {
  return (
    <div className="placeholder-container">
      <div className="placeholder">
            {
                animating ? 
                    <div className="placeholder-image" {...{style}}>
                        <img className="placeholder-image-webimg" src={placeholderImage} alt="Placeholder" />
                        <img className="placeholder-image-mobimg" src={placeholderImageMob} alt="Placeholder" />
                        <img className="placeholder-image-tabimg" src={placeholderImageTab} alt="Placeholder" />
                    </div>
                     : children 
            }
      </div>
    </div>
  )
}

Placeholder.propTypes = {
    placeholderImage: PropTypes.any.isRequired,
    placeholderImageMob: PropTypes.any.isRequired,
    placeholderImageTab: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
    animating: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

Placeholder.defaultProps = {
    animating: true
}


export default Placeholder;