export const server = "http://api.ecdcnepal.org/api/"; //LIVE API
// export const server = "http://192.168.1.130:8000/api/"; //ANKIT API

// export const server = "http://192.168.1.130/ecdcBackend/api/"; //ANKIT another API
export const config = {
    stories: server+'stories',
    achievements:server+'achievements',
    ourworks:server+'ourwork',
    timeline:server+'timeline',
    press:server+'media',
    mapdata:server+'maps',
    wishlist:server+'wishlist',
    postVolunteers:server+'volunteers',
    pushpaVideos:server+'pushpaVideos',
    workCategory:server+'projects/',
    postsubscriber: server+ 'subscribe',
    pushpanews: server+ 'pushpanews',
    articleDetail:server+'getarticles/',
    getActivites:server+'activities/10',
    getProducts:server+'products',
    productsdetail: server+'productsdetail/',
    buyecommerce: server+'buyecommerce',
    postfundRaiser:server+'fundraiser'
}