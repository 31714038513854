import axios from 'axios'
import {config} from '../config'
export const postfundRaiser =async (data) =>{
    // console.log("services data",data)
    let formData = new FormData();
    await formData.append('name',data.name)
    await formData.append('contact_number',data.contact_number)
    await formData.append('email',data.email)
    await formData.append('description',data.description)

    return axios.post(config.postfundRaiser,formData)
    .then(function (response) {
        // console.log('response cv',response)
        return response
    })
    .catch(function (error) {
      console.log('error',error);
    })
}
