import React from "react";

// IMPORT COMPONENTS

import SponsorCardSection from "./SponsorCardSection/SponsorCardSection.component"
import {OverlappedCircle} from "../common/floatingImage/Circle.common";

//IMPORT COMMONS

const BecomeASponsorPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <SponsorCardSection/>
            <OverlappedCircle/>
        </React.Fragment>
    )
}

export default BecomeASponsorPage;