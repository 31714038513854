import React from "react";



// IMPORT COMMON
import { OverlappedCard } from "../../common/overlappedCard/OverlappedCard.common";

// IMPORT PLACEHOLDER IMAGE
import OURMISSION from '../../../assets/images/OurMission.png'

const OurMissions = () => {
    return (
        <div className="ourmissions-container">
            <div className="ourmissions">
                <OverlappedCard image={OURMISSION} title={<>OUR <span className="text-light-teal text-font-rumpel">MISSION</span></>} />
            </div>
        </div>
    )
}

export default OurMissions;