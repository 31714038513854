import React, { useState, useEffect } from "react";
import * as moment from "moment";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMMONS
import DescriptionCard from "../../common/descriptionCard/DescriptionCard.common";
import { FloatButton } from "../../common/button/Button.common";

// IMPORT COMPONENTS
import OverlappedStoriesCard from "../overlappedStoriesCard/OverlappedStoriesCard.component";

// IMPORT PLACEHOLDER IMAGE
import Stories_Placeholder from "../../../assets/placeholder/Stories.svg";
import OurWork_Mobile from "../../../assets/placeholder/OurWork_Mobile.svg";
import OurWork_Tablet from "../../../assets/placeholder/OurWork_Tablet.svg";

//SERVICES
import { getStories } from "../../services/common/stories.service";
const StoriesSection = (props) => {
  const [stories, setStories] = useState(null);
  const [animating, setAnimating] = useState(true);
  const [callApi, setApiCall] = useState(true);
  useEffect(() => {
    if (callApi) {
      (async () => {
        let stories = await getStories();
        if (stories && stories.data.success) {
          setApiCall(false);
          setStories(stories.data.data.data);
          setAnimating(false);
        }
      })();
    }
    return () => {
      setApiCall(false);
    };
  });
  // console.log('data', stories)
  return (
    <CompWrapper
      style={{ marginTop: 100 }}
      title="STORIES"
      highlightText="STORIES"
    >
      <div className="storiessection-container">
        <div className="storiessection">
          <Placeholder
            placeholderImage={Stories_Placeholder}
            placeholderImageMob={OurWork_Mobile}
            placeholderImageTab={OurWork_Tablet}
            animating={animating}
            style={{ marginTop: 60 }}
          >
            {stories &&
              (window.innerWidth <= 1200 ? null : (
                <OverlappedStoriesCard
                  image={stories[0].thumbnail}
                  highlightText={stories[0].title.split(" ").slice(-1)}
                  title={stories[0].title}
                  description={stories[0].shortDescription}
                  onButtonClick={() =>
                    props.history.push(
                      `/detail-page/${stories[0].id}-${stories[0].slug_url}`
                    )
                  }
                  date={
                    <FloatButton
                      title={moment(stories[0].created_at).format(
                        "MMM DD, YYYY"
                      )}
                      highlightText={stories[0].created_at.slice(0, 3)}
                    />
                  }
                />
              ))}
            <div className="storiessection-smallcard">
              <div className="storiessection-smallcard-content">
                {stories &&
                  stories
                    .slice(window.innerWidth <= 1200 ? 0 : 1)
                    .map((item, index) => (
                      <div
                        key={index}
                        data-aos="fade-up"
                        data-aos-delay={index * 100}
                      >
                        <DescriptionCard
                          title={item.title}
                          description={item.shortDescription}
                          highlightText={item.title.split(" ").slice(-1)}
                          onButtonClick={() =>
                            props.history.push(
                              `/detail-page/${item.id}-${item.slug_url}`
                            )
                          }
                          source={item.thumbnail}
                          date={
                            <FloatButton
                              title={moment(item.created_at).format(
                                "MMM DD, YYYY"
                              )}
                              highlightText={item.created_at.slice(0, 3)}
                            />
                          }
                        />
                      </div>
                    ))}
              </div>
            </div>
          </Placeholder>
        </div>
      </div>
    </CompWrapper>
  );
};

export default StoriesSection;
