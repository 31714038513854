import React from "react";
import PropTypes from "prop-types";

// IMPORT COMPONENTS
import { FloatButton} from "../button/Button.common" ;

// IMPORT LOGOS
import Nutrients_1_orange from "../../../assets/icons/Nutrients_1_orange.png";
import TwoDash from "../../../assets/icons/Twodash_orange.svg";

const AchievementCard = ({ icon, defaultColor, highlightText, normalText, onButtonClick, buttonText, dash, contactCard, contactDetails }) =>{
    return(
        <div className="achievementcard-container">
            <div className="achievementcard">
                <div className="achievementcard-icon">
                    <img src = {icon} alt = "icon"/>
                </div>
                <div className="achievementcard-desc mt-5">
                    <div className = "mt-2 mb-4 text-thin text-center text-font-rumpel">
                        <span style = {{color: defaultColor}} className = "text-font-rumpel">{highlightText}</span> {normalText && normalText}
                    </div>
                    {contactDetails && 
                        <div className = "mt-2 mb-4 text-xthin text-center text-font-rumpel">
                            {contactDetails}
                        </div>
                    }
                   
                </div>
                <div className="achievementcard-twodash">
                    <img alt="FLOATING TWO DASH" src={dash} />
                </div>
                {!contactCard && 
                    <div className="achievementcard-float">
                        <FloatButton onClick={onButtonClick} highlightColor = {defaultColor} title= {buttonText} highlightText={buttonText} />
                    </div>
                }
                
            </div>
        </div>
    )
}

AchievementCard.propTypes = {
    icon: PropTypes.node.isRequired,
    defaultColor: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    normalText: PropTypes.string,
    dash: PropTypes.node.isRequired,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func,
    contactCard: PropTypes.bool,
    contactDetails: PropTypes.string
}

AchievementCard.defaultProps = {
    icon: Nutrients_1_orange,
    defaultColor: "#FF8144",
    highlightText: "Default Title",
    dash: TwoDash,
    buttonText: "Number",
    contactCard: false
}

export default AchievementCard;