import React from "react";

// IMPORT COMPONENTS
import OurWorkCategory from "./ourWorkCategory/OurWorkCategory.component";

// IMPORT COMMONS
import OurAchievements from "../common/ourAchievement/OurAchievement.component"; 
import {OverlappedCircle} from "../common/floatingImage/Circle.common";

const OurWorkCategoryPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
          <OurWorkCategory {...props}/>
          <OurAchievements {...props}/>
          <OverlappedCircle/>
        </React.Fragment>
    )
}

export default OurWorkCategoryPage;