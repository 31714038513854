import React, { useEffect } from "react";
import anime from "animejs";

// CUSTOM STYLES
const getStyles = () => ({
  svgContainer: {
    width: "100%",
    height: "100%",
    position: "relative"
  }
});

const FloatingElement = () => {
  const { svgContainer } = getStyles();

  const path_one = "M350.292,222.9C534.662,28.8,712.59,76.992,860.96,300.369S649.968,808.482,465.752,808.482,83.159,743.805,83.159,553.408,165.922,417.011,350.292,222.9Z";
  const path_two = "M503.963,145.349C718.3,97.414,1008.321,236.128,904.97,483.867S721.918,628.915,567.328,729.83,84.358,835.184,108.555,606.9,289.627,193.285,503.963,145.349Z";
  const path_three = "M536.089,115.138C750.424,67.2,1008.321,236.128,904.97,483.867S678.634,755.958,506.187,785.541,67.358,815.84,91.555,587.559,321.753,163.074,536.089,115.138Z";

  useEffect(() => {
    const timeline = anime.timeline({ 
      targets: ".floatElem", 
      easing: "linear", 
      duration: 6000,
      loop: true
    });
    timeline.add({ 
      d: { value: path_two } 
    });
    timeline.add({ 
      d: { value: path_three } 
    });
    timeline.add({
      d: { value: path_two }
    });
    timeline.add({
      d: { value: path_one }
    });
  }, []);

  return (
    <React.Fragment>
      <svg style={svgContainer} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 968.11 893.211">
        <g id="Floating_element_teal" transform="translate(-1229.743 -172.688)">
          <path className="floatElem" id="Path_5" data-name="Path 5" d={path_one} transform="matrix(0.966, -0.259, 0.259, 0.966, 1123.131, 306.488)" fill="#0061a6"/>
          <circle id="Ellipse_68" data-name="Ellipse 68" cx="9" cy="9" r="9" transform="translate(1846 658)" fill="#fff"/>
          <circle id="Ellipse_72" data-name="Ellipse 72" cx="3" cy="3" r="3" transform="translate(1764 929)" fill="#fff"/>
          <circle id="Ellipse_69" data-name="Ellipse 69" cx="18" cy="18" r="18" transform="translate(1846 616)" fill="#fff"/>
          <circle id="Ellipse_73" data-name="Ellipse 73" cx="4" cy="4" r="4" transform="translate(1728 288)" fill="#fff"/>
          <circle id="Ellipse_70" data-name="Ellipse 70" cx="4" cy="4" r="4" transform="translate(1881 654)" fill="#fff"/>
          <circle id="Ellipse_90" data-name="Ellipse 90" cx="104" cy="104" r="104" transform="translate(1738 331)" fill="#036DB9"/>
        </g>
    </svg>
    </React.Fragment>
  )
}

export default FloatingElement;