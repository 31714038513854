import React from 'react';

// IMPORT HOC
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMMONS
import { OverlappedCard } from "../../common/overlappedCard/OverlappedCard.common";

// IMPORT PLACEHOLDER IMAGE
import OverlappedCard_Placeholder from "../../../assets/placeholder/OverlappedCard.svg" 
import OverlappedCard_Mobile from "../../../assets/placeholder/OverlappedCard_Mobile.svg" 

import PUSHPAIMAGE from "../../../assets/images/pushpa.jpg"

const PushpaJourney = (props) => {
    return (
        <div className="pushpajourney-container">
            <div className="pushpajourney">
                <Placeholder placeholderImage={OverlappedCard_Placeholder} placeholderImageMob={OverlappedCard_Mobile} animating={false} style={{marginLeft: '10%'}}>
                    <OverlappedCard onButtonClick={()=>props.history.push(`/pushpas-journey`) } buttonVisible = {true} image={PUSHPAIMAGE} style={{backgroundPosition: "80% 50%"}}/>
                </Placeholder>
            </div>
        </div>
    )
}

export default PushpaJourney;