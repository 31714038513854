import React from 'react';

// IMPORT COMPONENTS
import ContactSection from './contactSection/ContactSection.component';

import { OverlappedCircle } from '../common/floatingImage/Circle.common';
import Map from '../common/map/map.common';
import CompWrapper from '../hoc/compWrapper/CompWrapper.hoc';

const ContactUsPage = () => {
  window.scroll(0, 0);
  return (
    <React.Fragment>
      {/* <MapSection /> */}
      {/* <div> */}
      <CompWrapper
        style={{ marginTop: 100 }}
        title="OUR LOCATION"
        highlightText="LOCATION"
      >
        <Map location={[27.76491857, 85.363376]} />
      </CompWrapper>
      {/* </div> */}
      <ContactSection />
      <OverlappedCircle />
    </React.Fragment>
  );
};
export default ContactUsPage;
