import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

// IMPORT ICONS
import ScrollTopIcon from "../../../assets/icons/Scroll_top.png";

const ScrollToTop=()=>{
    const [visible,setVisible]=useState(false);

    useEffect(()=>{
        window.addEventListener("scroll", function() {
            toggleVisibility();
        });
    })

    const toggleVisibility=()=> {
        if (window.pageYOffset > 600) {
            setVisible(true);
        } else {
            setVisible(false);
        }
      }
    
    const scrollToTop=()=> {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }
    
    return(
        <div className="scrolltotop-container">
            {
                <CSSTransition
                    in={visible}
                    timeout={200}
                    classNames="scrolltotop-node"
                    unmountOnExit
                >
                    <div 
                        className="scrolltotop" 
                        onClick={() => scrollToTop()}
                    >
                        <img src={ScrollTopIcon} alt="up"/>
                    </div>
                </CSSTransition>
            }
        </div>
    )
}

export default ScrollToTop;
