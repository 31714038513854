import React from "react";


// IMPORT COMPONENTS
import SupportUs from "./supportUs/SupportUs.component";
import MoreWaysToDonate from "./moreWaysToDonate/MoreWaysToDonate.component";
import {OverlappedCircle} from "../common/floatingImage/Circle.common";

const GetInvolvedPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <SupportUs {...props} />
            <MoreWaysToDonate {...props}/>
            <OverlappedCircle/>
        </React.Fragment>
    )
}

export default GetInvolvedPage;