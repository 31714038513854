import React, { useState, useEffect } from "react";

// IMPORT COMMON
import DetailCard from "../../common/detailCard/DetailCard.common";

// IMPORT COMPONENTS
import Quote from "../quote/Quote.component";

//IMPORT HOC
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

//IMPORT ICON
import DetailCardPlaceholder from "../../../assets/placeholder/DetailCardPlaceholder.svg";

//IMPORT SERVICES
import { getProductDetails } from "../../services/products/products";

const ProductDetailSection = (props) => {
  const [product, setProduct] = useState(null);
  const [animating, setAnimating] = useState(true);
  const [callApi, setApiCall] = useState(true);
  useEffect(() => {
    var id = props.match.params.id;
    if (callApi) {
      (async () => {
        let productResponse = await getProductDetails(id);
        if (productResponse && productResponse.data.success) {
          setApiCall(false);
          setProduct(productResponse.data.data[0]);
          setAnimating(false);
        }
      })();
    }
    return () => {
      setApiCall(false);
    };
  });
  // console.log('data aha', product)
  return (
    <div className="detailcardsection-container">
      <div className="detailcardsection">
        <Placeholder
          placeholderImage={DetailCardPlaceholder}
          placeholderImageMob={DetailCardPlaceholder}
          placeholderImageTab={DetailCardPlaceholder}
          animating={animating}
          style={{ marginTop: 60, marginRight: "7.5%" }}
        >
          {product && (
            <DetailCard
              title={product.product_name}
              highlightText={product.product_name.split(" ").slice(-1)}
              description={product.product_desc}
              images={product.images.map((item) => {
                return item.images;
              })}
            />
          )}
        </Placeholder>
        <Quote productDetails={product} />
      </div>
    </div>
  );
};

export default ProductDetailSection;
