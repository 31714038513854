import React,{useEffect,useState} from "react";

// IMPORT COMPONENTS
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc"
import YoutubeSlider from "../../common/videoSlider/YoutubeSlider.component"


import Cropped_Twodash_blue from "../../../assets/icons/Cropped_Twodash_blue.svg";

//SERVICES
import {getVideos} from "../../services/journey/pushpavideos.service"

const JourneyPage = () => {
    const [videos,setVideos] = useState(null);
    // const [animating,setAnimating] = useState(true);
    const [callApi,setApiCall] = useState(true);
    useEffect(() => {
        if(callApi){
          (async()=> {
            let videos = await getVideos();
            if(videos && videos.data.success){
              setApiCall(false);
              setVideos(videos.data.data);
              // setAnimating(false);
            }
          })();
        }
          return () =>{
            setApiCall(false);
          }
      });
    return (
        <CompWrapper title="PUSHPA'S VIDEOS" highlightText="VIDEOS" style={{background:"white",marginTop:100,position:"relative"}}>
        <div className="videoplay-container" >
            <div className="videoplay">
            <img alt="FLOATING TWO DASH" src={Cropped_Twodash_blue} className="videoplay-img"/>
                {/* <div> */}
                    {/* ECDC began as a children&apos;s home to offer a safe place for children in need */}
                {/* </div> */}
        {videos && <YoutubeSlider sliderImages={videos} /> }
            </div>

        </div>
        </CompWrapper>
    )
}

export default JourneyPage;