import React from "react";

// IMPORT COMPONENTS
import WishListDonate from "./wishListDonate/WishListDonate.component"
import WishListCards from "./wishListCards/WishListCards.component"

//IMPORT COMMONS
import {OverlappedCircle} from "../common/floatingImage/Circle.common";

const WishListPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
           <WishListDonate {...props}/>
           <WishListCards {...props}/>
           <OverlappedCircle/>
        </React.Fragment>
    )
}

export default WishListPage;