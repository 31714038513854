import axios from 'axios'
import {config} from '../config'
export const postVolunteers =async (data) =>{
    let formData = new FormData();
    await formData.append('name',data.fullname)
    await formData.append('contact',data.contact)
    await formData.append('email',data.email)
    await formData.append('contribution',data.contribution)
    await formData.append('experience',data.experience)
    await formData.append('expertize',data.expertize)
    await formData.append('cv', data.cv[0]);
    // console.log(formData.get('name'))
    return axios.post(config.postVolunteers,formData
    )
    .then(function (response) {
        // console.log('response cv',response)
        return response
    })
    .catch(function (error) {
      console.log('error',error);
    })
}

export const postSubscriber =async (data) =>{
  return axios.post(config.postsubscriber,{
    email: data.email
  }
  )
  .then(function (response) {
      // console.log('response',response)
      return response
  })
  .catch(function (error) {
    console.log('error',error);
  })
}

