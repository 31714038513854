import React,{useState,useEffect} from "react";

// IMPORT COMMON
import DetailCard from "../../common/detailCard/DetailCard.common";

//IMPORT HOC
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

//IMPORT ICON
import DetailCardPlaceholder from "../../../assets/placeholder/DetailCardPlaceholder.svg"

//IMPORT SERVICES
import {getArticleDetail} from "../../services/articles/articles.service"

const CategoryDetailSection = (props) => {
    const [article,setArticle] = useState(null);
    const [callApi,setApiCall] = useState(true);
    const [animating,setAnimating] = useState(true);
    useEffect(() => {
        var id = props.match.params.id
        if(callApi){
          (async()=> {
            let article = await getArticleDetail(id);
            if(article && article.data.success){
              setApiCall(false);
              setArticle(article.data.data[0]);
              setAnimating(false)
              // setParams(stories.data.data.data)
            }
          })();
        }
          return () =>{
            setApiCall(false);
          }
      });
    return (
            <div className="detailcardsection-container">
                <div className="detailcardsection">
                <Placeholder placeholderImage={DetailCardPlaceholder} placeholderImageMob={DetailCardPlaceholder} placeholderImageTab={DetailCardPlaceholder}  animating={animating} style={{ marginTop: 60,marginRight:"7.5%" }}>
                   {article && <DetailCard
                    title={article.title}
                    highlightText={article.title.split(" ").slice(-1)}
                    description={article.description}
                    images={article.images.map((item,index)=>{return item.images})}
                   />}
                   </Placeholder>
                </div>
            </div>
    )
}

export default CategoryDetailSection;