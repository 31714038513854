import React from "react";
import ReactDOM from "react-dom";
import AOS from "aos";

// IMPORT APP
import AppWithRouter from './components/app/App';

// IMPORT STYLES
import "./sass/main.scss";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

// INIT AOS
AOS.init();

ReactDOM.render(<AppWithRouter />, document.querySelector("#root"));