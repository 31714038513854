import React, { useState, useEffect } from "react";

// IMPORT COMPONENTS

//IMPORT COMMONS
import ActivitiesCard from "./activitiesCard/ActivitiesCard.common";

import Activities_Placeholder from "../../assets/placeholder/Activities_Placeholder.svg";

//IMPORT HOC
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../hoc/placeholder/Placeholder.hoc";

//SERVICES
import { getActivities } from "../services/activities/activities.service";
const ActivitiesPage = (props) => {
  window.scrollTo(0, 0);
  const [activities, setActivities] = useState(null);
  const [callApi, setApiCall] = useState(true);
  const [animating, setAnimating] = useState(true);
  useEffect(() => {
    if (callApi) {
      (async () => {
        let activities = await getActivities();
        // console.log("activities",activities)
        if (activities && activities.data.success) {
          setApiCall(false);
          setActivities(activities.data.data.data);
          setAnimating(false);
        }
      })();
    }
    return () => {
      setApiCall(false);
    };
  });
  return (
    <React.Fragment>
      <CompWrapper
        style={{ marginTop: 100 }}
        title="New Post"
        highlightText="Post"
      >
        <div style={{ marginTop: 30 }}>
          <Placeholder
            placeholderImage={Activities_Placeholder}
            placeholderImageMob={Activities_Placeholder}
            placeholderImageTab={Activities_Placeholder}
            animating={animating}
            style={{ marginTop: 60 }}
          >
            {activities &&
              activities.map((item, index) => {
                return (
                  <ActivitiesCard
                    key={index}
                    title={item.title}
                    highlightText={item.title.split(" ").slice(-1)}
                    description={item.description}
                    animating={animating}
                    images={item.images.map((item) => {
                      return item.image;
                    })}
                    // imagesdata={activities}
                  />
                );
              })}
          </Placeholder>
        </div>
      </CompWrapper>
    </React.Fragment>
  );
};

export default ActivitiesPage;
