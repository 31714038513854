import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import { NextButton, PrevButton } from "../../common/prevNextButton/PrevNextButton.common"
import Left_Arrow_White from "../../../assets/icons/Left_Arrow_White.png";
import Right_Arrow_White from "../../../assets/icons/Right_Arrow_White.png";


class YoutubeSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            width: 1920
        };
    
        window.addEventListener("resize", this.widthUpdate);
    }

    widthUpdate =()=>{
        this.setState({
          width: window.innerWidth
        });
      }

    componentDidMount() {
        this.widthUpdate();

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    emebeddedVideo(){
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => {
        iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
        func: 'stopVideo' }), '*');
        });
    }

 
    render() {
        const {sliderImages} =this.props
        const slider1Settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 890,
                  settings: {
                    dots:true,
                    arrows: false,
                    slidesToShow: 1,
                    infinite: true,
                    speed: 500,
                    slidesToScroll: 1,
                  }
                }
              ]
        };
        const slider2Settings = {
           
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            centerMode: true,
            centerPadding: '60px',
            // slidesToShow: this.state.width <= 768 ? 1 : 5,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                  }
                }
              ]
        };
        return (

                <div className="videoslider-container">
                    <div className="videoslider">
                        <Slider
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            {...slider1Settings}
                        >
                            {
                                sliderImages &&
                                sliderImages.length &&
                                sliderImages.map((val, index) => (
                                    <div key={index}>
                                    <div>
                                    {val.title}
                                    </div>
                                    <div  className="videoslider-slider1" >
                                        <iframe title={index} className="youtube-video" width="100%" height="506" src={`https://www.youtube.com/embed/${val.videoId}?version=3&enablejsapi=1`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    </div>
                                ))
                            }
                        </Slider>

                        <div className="videoslider-slider2-container" >
                            <PrevButton  icon={Left_Arrow_White} onClick={()=>{this.slider2.slickPrev();this.emebeddedVideo()}}/>
                            <div className="videoslider-slider2" > 
                                <Slider
                                    {...slider2Settings}
                                    asNavFor={this.state.nav1}
                                    ref={slider => (this.slider2 = slider)}
                                    slidesToShow={sliderImages.length >= 5 ? 5 : sliderImages.length }
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    style={{width:"90%",margin:"0 auto"}}
                                    >
                                    {
                                        sliderImages &&
                                        sliderImages.length &&
                                        sliderImages.map((val, index) => (
                                            <div key={index} className="videoslider-slider2-each">
                                                <img alt="ECDC Images" className="videoslider-slider2-each-image" src={`https://img.youtube.com/vi/${val.videoId}/0.jpg`}  onClick={()=>{this.emebeddedVideo()}}/>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                            <NextButton style={{position:"absolute",right:0,top:13}} icon={Right_Arrow_White} onClick={()=>{this.slider2.slickNext();this.emebeddedVideo()}}/>
                        </div>
                    </div>
                </div>
        )
    }
}

YoutubeSlider.propTypes = {
    sliderImages: PropTypes.node.isRequired,
};

export default YoutubeSlider;