import axios from 'axios'
import {config} from '../config'
export const getworkCategory = (id) =>{
    return axios.get(`${config.workCategory}${id}`)
    .then(function (response) {
        return response
    })
    .catch(function (error) {
      console.log(error);
    })
}

