import React from "react";
import PropTypes from "prop-types";

// IMPORT IMAGES
import NoImageAvailable from "../../../assets/images/no_img_available.jpg";
import Cropped_Twodash_white from "../../../assets/icons/Cropped_Twodash_white.svg";
import Cropped_Twodash_blue from "../../../assets/icons/Cropped_Twodash_blue.svg";

// IMPORT COMMONS 
import { DefaultButton } from "../button/Button.common";
import { WhiteCircle } from "../floatingImage/Circle.common";
import { SemiCircle } from "../floatingImage/Circle.common";

// OVERLAPPED CARD LEFT
export const OverlappedCard = ({ image, title, description, buttonVisible, onButtonClick, style }) =>{
    return(
        <div className="overlappedcard-container">
            <div className="overlappedcard">
                <div className="overlappedcard-right">
                    <WhiteCircle/>
                    <div className="overlappedcard-right-title text-font-rumpel text-xlg">
                        {title}
                    </div>
                    <div className="overlappedcard-right-desc text-white text-font-averta text-thin">
                        {description}
                    </div>
                    {
                        buttonVisible &&
                        <div className="overlappedcard-right-button">
                            <DefaultButton onClick={onButtonClick} defaultInvert={true} style = {{paddingLeft: 30, paddingRight: 30}} title="EXPLORE MORE" />
                        </div>
                    }
                    <div className="overlappedcard-right-twodash">
                        <img alt="FLOATING TWO DASH" src={Cropped_Twodash_white} />
                    </div>
                </div>
                <div data-aos="fade-up" className="overlappedcard-left" style={{backgroundImage: `url(${image})`, ...style}} />
            </div>
        </div>
    )
}

OverlappedCard.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    buttonVisible: PropTypes.bool,
    onButtonClick: PropTypes.func,
    style: PropTypes.any
};
  
OverlappedCard.defaultProps = {
    image: NoImageAvailable,
    title: <>EXPLORE <br />PUSHPA&apos;S <span className="text-grey text-font-rumpel">JOURNEY</span></>,
    description: "How Pushpa Basnet changed lives of many children and became a CNN Superhero.",
    buttonVisible: false,
    onButtonClick: () => false
};

// OVERLAPPED CARD INVERTED
export const OverlappedCardInverted = ({ image, title, description, style }) =>{
    return(
        <div className="overlappedcardinverted-container">
            <div className="overlappedcardinverted">
                <SemiCircle/>
                <div className="overlappedcardinverted-left">
                    <div className="overlappedcardinverted-left-title text-font-rumpel text-xlg">
                        {title}
                    </div>
                    <div className="overlappedcardinverted-left-desc text-black text-font-averta text-thin">
                        {description}
                    </div>
                    <div className="overlappedcardinverted-left-twodash">
                        <img alt="FLOATING TWO DASH" src={Cropped_Twodash_blue} />
                    </div>
                </div>
                <div data-aos="fade-up" {...{style}} className="overlappedcardinverted-right" style={{backgroundImage: `url(${image})`}} />
            </div>
        </div>
    )
}

OverlappedCardInverted.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    style: PropTypes.any
};
  
OverlappedCardInverted.defaultProps = {
    image: NoImageAvailable,
    title: <>OUR <span className="text-blue text-font-rumpel">STAFF</span></>,
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
};

export default OverlappedCard;