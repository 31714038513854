import React from "react";



// IMPORT COMMON
import { OverlappedCardInverted } from "../../common/overlappedCard/OverlappedCard.common";

// IMPORT PLACEHOLDER IMAGE
import ABOUTUS from "../../../assets/images/AboutUs.png"
const description = `Founded in 2005 by a young Nepali woman named Pushpa Basnet at the age of 21, the Early Childhood Development Center (ECDC) a registered non-governmental, non-profit organization aims at providing a homely environment for the children living in prison with their parents. This may also mean rescuing the children from serving their parents’ sentences and helping them enjoy their fundamental rights – food, clothes, shelter and education.

In Nepal, there are only a few organizations that work for the welfare of the prisoners and there are hardly any projects operating to look after the children staying in prison along with their parents. Even the government has not allocated any specific budget as such for these innocent children serving the prison sentences of their parents. The need to provide a strong foundation for the construction of their behavior and values of the young inmates is strongly felt, so Early Childhood Development Centre (ECDC) was founded.`

const AboutUs = () => {
    return (
        <div className="aboutus-container">
            <div className="aboutus">
                <OverlappedCardInverted description={description} image={ABOUTUS} title={<>OUR <span className="text-blue text-font-rumpel">HISTORY</span></>}/>
            </div>
        </div>
    )
}

export default AboutUs;