import React from "react";

// IMPORT COMMONS
import OurAchievement from "../common/ourAchievement/OurAchievement.component";


// IMPORT COMPONENTS
import CategoryDetailSection from "./CategoryDetailSection/CategoryDetailSection.component";
import OurWorks from "../homePage/ourWork/OurWork.component";
import {OverlappedCircle} from "../common/floatingImage/Circle.common";


const DetailPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
          <CategoryDetailSection {...props} />
          <OurWorks {...props} />
          <OurAchievement {...props} />
          <OverlappedCircle/>
        </React.Fragment>
    )
}

export default DetailPage;