import React from "react";
import PropTypes from "prop-types";

// IMPORT COMPONENTS
import { FloatButton, InvertedButton} from "../button/Button.common";

// IMPORT LOGOS
import Donate_teal from "../../../assets/icons/Donate_teal.png";

const SupportCard = ({ icon, defaultColor,  title, highlightText, description, onButtonClick, buttonText, style,inverted }) =>{

    if(title.indexOf(highlightText) < 0) {
        console.error("NO HIGHLIGHT PART");
    }

    let titleWithoutHighlight = title.slice(0, title.indexOf(highlightText));
    let titleWithHighlight = title.slice(title.indexOf(highlightText), title.length);

      
    return(
        <div className="supportCard-container">
            <div className="supportCard">
                <div className="supportCard-icon">
                    <div {...{style}} className="supportCard-icon-content">
                        <img src = {icon} alt = "icon"/>
                    </div>
                </div>
                <div className="supportCard-desc">
                    <div className="supportCard-desc-title mb-4 text-sm text-font-rumpel">
                        { titleWithoutHighlight } <span style = {{color: defaultColor}} className="text-font-rumpel">{ titleWithHighlight }</span>
                        <div style = {{backgroundColor: defaultColor}} className="supportCard-desc-title-after" />
                    </div>
                    

                    <div className="supportCard-desc-text text-xthin mb-4" >
                        {description}
                    </div>
                </div>
                <div className="supportCard-float">
                    {inverted?
                    <InvertedButton onClick={onButtonClick} style={{background:defaultColor,border:"none"}} title= {buttonText} highlightText={buttonText} />
                    :
                    <FloatButton onClick={onButtonClick} highlightColor = {defaultColor} title= {buttonText} highlightText={buttonText} />
                    }
                </div>
            </div>
        </div>
    )
}

SupportCard.propTypes = {
    icon: PropTypes.node.isRequired,
    defaultColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    inverted:PropTypes.bool,
    onButtonClick: PropTypes.func,
}

SupportCard.defaultProps = {
    icon: Donate_teal,
    defaultColor: "#3180A9",
    title: "Default Title",
    highlightText: "Title",
    description: "Default description",
    buttonText: "Number",
}

export default SupportCard;