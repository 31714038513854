import React from "react";

// IMPORT COMMONS
import OurAchievement from "../common/ourAchievement/OurAchievement.component";

// IMPORT COMPONENTS
import StoriesSection from "./storiesSection/StoriesSection.component";
import {OverlappedCircle} from "../common/floatingImage/Circle.common";

const StoriesPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <StoriesSection {...props} />
            <OurAchievement {...props}/>
            <OverlappedCircle/>
        </React.Fragment>
    )
}

export default StoriesPage;