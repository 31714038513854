import React, { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

// IMPORT COMMONS
import { InvertedButton } from "../button/Button.common";

// IMPORT LOGO
import EcdcLogo from "../../../assets/icons/Ecdclogo_nobg.svg";
import ECDCMenu from "../../../assets/icons/ECDC_Menu.svg";
import ECDCMenuCross from "../../../assets/icons/Menu_Cross.svg";
import Dropdown from "../../../assets/icons/Dropdown.png";

const Header = props => {
    const [navInProp, setNavInProp] = useState(false);
    const [mobileDrop, setMobileDrop] = useState(false);

    const toggleMenu = () => {
        setNavInProp(!navInProp);
    }

    const handleOutsideClick = e => {
        if(e.currentTarget === e.target) {
            setNavInProp(false);
        }
    }

    const handleMobileDropdown = (event) => {
        event.preventDefault();
        setMobileDrop(mobileDrop ? false: true );
    }
    return (
        <div className="header-container">
            <div className="header">
                <div className="header-left" onClick={() => props.history.push("/")}>
                    <img alt="ECDC LOGO" src={EcdcLogo} />
                </div>

                <div className="header-right">
                    <ul className="header-nav">
                        <li className="header-nav-item"><NavLink to="/" activeClassName="active-tab" exact={true}>Home</NavLink></li>
                        <li className="header-nav-item"><NavLink to="/our-work" activeClassName="active-tab">Our&nbsp;Work</NavLink></li>
                        <li className="header-nav-item"><NavLink to="/stories" activeClassName="active-tab">Stories</NavLink></li>
                        <li className="header-nav-dropdown">
                            <NavLink to="/about-us" activeClassName="active-tab drop-btn" onClick={ (event) => event.preventDefault() }>About&nbsp;Us</NavLink>
                            <div className="header-nav-dropdown-content"> 
                                <div className="header-nav-dropdown-content-inner">
                                    <NavLink to="/about-us/about" activeClassName="active-sub-tab">ABOUT</NavLink>
                                    <div />
                                    <NavLink to="/about-us/new-post" activeClassName="active-sub-tab">NEW POST</NavLink>
                                    <div/>
                                    <NavLink to="/about-us/working-process" activeClassName="active-sub-tab">OUR WORKING PROCESS</NavLink>
                                </div>
                            </div>
                            
                        </li>
                        <li className="header-nav-item"><NavLink to="/get-involved" activeClassName="active-tab">Get&nbsp;Involved</NavLink></li>
                        <li className="header-nav-item">
                            <InvertedButton style={{boxShadow: "none"}} title="DONATE&nbsp;NOW" onClick={() => window.open("https://utopiafound.kindful.com/?campaign=228328")} />
                        </li>
                    </ul>
                    
                    <div className="header-menu" onClick={toggleMenu}>
                        <img alt="MENU" src={ECDCMenu} />
                    </div>
                </div>
            </div>

            <CSSTransition 
                in={navInProp} 
                timeout={200} 
                classNames="nav-anims"
                unmountOnExit
            >
                <div className="header-mobile-container" onClick={handleOutsideClick}>
                    <div className="header-mobile">
                        <div className="header-mobile-top">
                            <div className="header-mobile-top-title">
                                ECDC
                            </div>

                            <div className="header-mobile-top-close" onClick={toggleMenu}>
                                <img alt="CLOSE MENU" src={ECDCMenuCross} />
                            </div>
                        </div>

                        <ul className="header-mobile-nav">
                            <li className="header-mobile-nav-item"><NavLink to="/" activeClassName="active-tab" exact={true}>Home</NavLink></li>
                            <li className="header-mobile-nav-item"><NavLink to="/our-work" activeClassName="active-tab">Our&nbsp;Work</NavLink></li>
                            <li className="header-mobile-nav-item"><NavLink to="/stories" activeClassName="active-tab">Stories</NavLink></li>
                            <li className="header-mobile-nav-item header-mobile-nav-drop">
                                <div className="header-mobile-nav-drop-handler" onClick={(event) => handleMobileDropdown(event) }>
                                    <NavLink to="/about-us" activeClassName="active-tab" onClick={(event) => handleMobileDropdown(event) } >About&nbsp;Us</NavLink>
                                    <img style={{transform: mobileDrop ? 'rotate(180deg)': 'rotate(0deg)' }} alt = "Dropdown" src = {Dropdown}/>
                                </div>
                                
                                {
                                    mobileDrop ? 
                                    <div className="header-mobile-nav-drop-inner">
                                        <div className="header-mobile-nav-drop-inner-container">
                                            <div className="header-mobile-nav-drop-inner-container-dots"></div>
                                            <NavLink to="/about-us/about" activeClassName="active-sub-tab">About</NavLink>
                                        </div>
                                        <div className="header-mobile-nav-drop-inner-container">
                                            <div className="header-mobile-nav-drop-inner-container-dots"></div>
                                            <NavLink to="/about-us/new-post" activeClassName="active-sub-tab">New Post</NavLink>
                                        </div>
                                        <div className="header-mobile-nav-drop-inner-container">
                                            <div className="header-mobile-nav-drop-inner-container-dots"></div>
                                            <NavLink to="/about-us/working-process" activeClassName="active-sub-tab">Our Work Process</NavLink>
                                        </div>
                                    </div> : null
                                }
                            </li>
                            <li className="header-mobile-nav-item"><NavLink to="/get-involved" activeClassName="active-tab">Get&nbsp;Involved</NavLink></li>
                        </ul>

                        <div className="header-mobile-donatebutton">
                            <InvertedButton title="DONATE&nbsp;NOW" onClick={() => window.open("https://utopiafound.kindful.com/?campaign=228328")} />
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default withRouter(Header);