import React,{ useState, useEffect } from "react";
import * as moment from 'moment';
// IMPORT HOCS
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";


// IMPORT COMMONS
import Timeline from "../../common/timeline/Timeline.common";
import {SubCircle,Circle} from "../../common/floatingImage/Circle.common";


// IMPORT PLACEHOLDER IMAGE
import Timeline_Placeholder from "../../../assets/placeholder/Timeline.svg"

//SERVICES
import {getTimeline} from "../../services/aboutus/timeline"

const OurHistoryTimeline = () => {
  const [timeline,setTimeline] = useState(null);
  const [animating,setAnimating] = useState(true);
  const [callApi,setApiCall] = useState(true);
  useEffect(() => {
    if(callApi){
      (async()=> {
        let timeline = await getTimeline();
        if(timeline && timeline.data.success){
          setApiCall(false);
          setTimeline(timeline.data.data);
          setAnimating(false)
        }
      })();
    }
      return () =>{
        setApiCall(false);
      }
  });
  return (

    <CompWrapper style={{marginTop: 100}} title="OUR HISTORY TIMELINE" highlightText="TIMELINE">
      <div className="ourhistorytimeline-container">
        <div className="ourhistorytimeline" >
          <Circle/>
          <SubCircle/>
          <Placeholder placeholderImage={Timeline_Placeholder} placeholderImageMob={Timeline_Placeholder} placeholderImageTab={Timeline_Placeholder} animating={animating} style={{marginTop: 60, width: '70%', marginLeft: 'auto', marginRight: 'auto'}}>
            {timeline &&  <Timeline data={timeline.map(({date,title,description, image})=>{
              return(
                {
                  date:moment(date).format('MMM DD, YYYY'),
                  dateHighlight:date.split('-')[0],
                  title,
                  description,
                  highlightText:title.split(" ").slice(-1),
                  image
                }
              )
            })}/> }
          </Placeholder>
        </div>
      </div>
    </CompWrapper>
  )
}

export default OurHistoryTimeline;