import React from "react";

// IMPORT COMPONENTS
import VideoPlay from "./videoPlay/VideoPlay.component"
import PushpasJourney from "./pushpasJourney/PushpasJourney.component"
// import PushpaNews from "./pushpaNews/PushpaNews.component";
import OurAchievement from "../common/ourAchievement/OurAchievement.component";
import {OverlappedCircle} from "../common/floatingImage/Circle.common";
const JourneyPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
          <PushpasJourney/>
          <VideoPlay/>
          {/* <PushpaNews /> */}
          <OurAchievement {...props} />
          <OverlappedCircle/>
        </React.Fragment>
    )
}

export default JourneyPage;