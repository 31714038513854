import React from "react";

// COMPONENTS
import ProductCardSection from "./productCardSection/ProductCardSection.component";

import {OverlappedCircle} from "../common/floatingImage/Circle.common";


const ProductsPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <ProductCardSection {...props}/>
            <OverlappedCircle/>
        </React.Fragment>
    )
}

export default ProductsPage;