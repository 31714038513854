import React from "react";

// IMPORT COMPONENTS
import AboutUs from "./aboutUs/AboutUs.component";
import PressAndMedia from "./pressAndMedia/PressAndMedia.component";
import OurHistoryTimeline from "./ourHistoryTimeline/OurHistoryTimeline.component";
import OurMissions from "./ourMissions/OurMissions.component";
import OurStaffs from "./ourStaffs/OurStaffs.component";


//IMPORT COMMONS
import OurAchievements from "../common/ourAchievement/OurAchievement.component";
import {OverlappedCircle} from "../common/floatingImage/Circle.common";

const AboutUsPage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <AboutUs />
            <OurHistoryTimeline />
            <PressAndMedia />
            <OurMissions />
            <OurStaffs />
            <OurAchievements {...props}/>
            <OverlappedCircle/>
        </React.Fragment>
    )
}

export default AboutUsPage;