import axios from 'axios'
import {config} from '../config'


export const getProducts = () =>{
    return axios.get(config.getProducts)
    .then(function (response) {
        return response
    })
    .catch(function (error) {
      console.log(error);
    })
}


export const getProductDetails = (id) =>{
  return axios.get(config.productsdetail + id)
  .then(function (response) {
      return response
  })
  .catch(function (error) {
    console.log(error);
  })
}

export const buyecommerce = (data, product_name) =>{
  console.log('data', data, product_name)
  return axios.post(config.buyecommerce, {
    full_name: data.fullname,
    email: data.email,
    location: data.location,
    message: data.message,
    product_name: product_name,
    number_of_item: data.quantity,
  })
  .then(function (response) {
      return response
  })
  .catch(function (error) {
    console.log(error);
  })
}
