import React, { useEffect } from "react";

// IMPORT COMPONENTS
import MapNepal from "./mapNepal/MapNepal.component";
import MapNepalResponsive from "./mapNepal/MapNepalResponsive.component";
import OurWork from "./ourWork/OurWork.component";

// IMPORT COMMONS
import OurAchievements from "../common/ourAchievement/OurAchievement.component"; 
import {OverlappedCircle} from "../common/floatingImage/Circle.common";

const OurWorkPage = (props) => {
  const [screenWidth, setScreenWidth] = React.useState();
   
  const widthUpdate = () => {
    // console.log("width",window.innerWidth)
    setScreenWidth(window.innerWidth)
  };
  window.addEventListener("resize", widthUpdate);

  useEffect(()=>{
    widthUpdate()
  },[])

    window.scrollTo(0,0);
    return (
        <React.Fragment>
          <OurWork {...props}/>
          {screenWidth<768?
          <MapNepalResponsive/>          :<MapNepal />}


          <OurAchievements {...props}/>
          <OverlappedCircle/>
        </React.Fragment>
    )
}

export default OurWorkPage;