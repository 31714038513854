import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// IMPORT COMMONS
import Header from "../common/header/Header.common";
import Footer from "../common/footer/Footer.common";
import ScrollToTop from "../common/scrollToTop/ScrollToTop.common";
// ROUTES
import HomePage from "../homePage/HomePage";
import OurWorkPage from "../ourWorkPage/OurWorkPage";
import StoriesPage from "../storiesPage/StoriesPage";
import AboutUSPage from "../aboutUsPage/AboutUsPage";
import GetInvolvedPage from "../getInvolvedPage/GetInvolvedPage";
import JourneyPage from "../journeyPage/JourneyPage";
import BecomeVolunteerPage from "../becomeVolunteerPage/BecomeVolunteerPage";
import DetailPage from "../detailPage/DetailPage";
import WishListPage from "../wishListPage/WishListPage";
import ItemsToBringPage from "../itemsToBringPage/ItemsToBringPage";
import BecomeSponsorPage from "../becomeSponsorPage/BecomeSponsorPage";
import ActivitiesPage from "../activitiesPage/ActivitiesPage";
import OurWorkCategoryPage from "../ourWorkCategoryPage/OurWorkCategoryPage";
import CategoryDetailPage from "../categoryDetailPage/CategoryDetailPage";
import ContactUs from "../contactUsPage/ContactUsPage";
import ProductsPage from "../productsPage/ProductsPage";
import ProductDetailPage from "../productDetailPage/ProductDetailPage";
import WorkInProcessPage from "../workInProcessPage/WorkInProcessPage";

// ALL ROUTES ARE HERE
const App = () => {
    return (
        <Switch>
            <Route exact path="/our-work" component={OurWorkPage} />
            <Route exact path="/our-work/:id" component={OurWorkCategoryPage} />
            <Route exact path="/our-work/category/:id" component={CategoryDetailPage} />
            <Route exact path="/stories" component={StoriesPage} />
            <Route exact path="/about-us/about" component={AboutUSPage} />
            <Route exact path="/about-us/new-post" component={ActivitiesPage} />
            <Route exact path="/about-us/working-process" component={WorkInProcessPage} />
            <Route exact path="/get-involved" component={GetInvolvedPage} />
            <Route exact path="/pushpas-journey" component={JourneyPage} />
            <Route exact path="/get-involved/volunteer" component={BecomeVolunteerPage} />
            <Route exact path="/detail-page/:id" component={DetailPage} />
            <Route exact path="/get-involved/wishlist" component={WishListPage} />
            <Route exact path="/get-involved/items-to-bring" component={ItemsToBringPage} />
            <Route exact path="/get-involved/become-a-sponsor" component={BecomeSponsorPage} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/products" component={ProductsPage} />
            <Route exact path="/products/detail/:id" component={ProductDetailPage} /> 
            <Route exact path="/" component={HomePage} />
            <Route render={()=><p className="text-center text-lg text-font-rumpel text-black" style = {{marginTop: '200px'}}>404 Notfound</p>} />
        </Switch>
    )
}

// SHARED COMPONENTS AND ROUTER
const AppWithRouter = (props) => {
    return(
        <React.Fragment>
            <Router>
                <Header />
                
                <App />

                <Footer {...props}/>
                <ScrollToTop/>
            </Router>
        </React.Fragment>
    )
}

export default AppWithRouter;