import React from "react";
import PropTypes from "prop-types";

// IMPORT IMAGES
import NoImageAvailable from "../../../assets/images/no_img_available.jpg";



const PressCard = ({icon, title, highlightText, description, url}) =>{

    if(title.indexOf(highlightText) < 0) {
        console.error("NO HIGHLIGHT PART");
    }
    
    let titleWithoutHighlight = title.slice(0, title.indexOf(highlightText));
    let titleWithHighlight = title.slice(title.indexOf(highlightText), title.length);

    return(
        <div className="presscard-container"onClick={() => window.open(url)}>
            <div className="presscard">
                <div className="presscard-icon">
                    <img src = {icon} alt = "icon"/>
                </div>
                <div className="presscard-desc">
                    <div className="presscard-desc-title mt-5 mb-4 text-sm text-font-rumpel">
                        { titleWithoutHighlight } <span className="text-blue text-font-rumpel">{ titleWithHighlight }</span>
                    </div>

                    <div className="presscard-desc-text text-xthin">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}

PressCard.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string
}

PressCard.defaultProps = {
    icon: NoImageAvailable,
    title: "Default Title",
    highlightText: "Title",
    description: "Default Description Default Description",
}

export default PressCard;