import React from "react"
import PropTypes from "prop-types";

// IMPORT LOGOS
import Left_Arrow_White from "../../../assets/icons/Left_Arrow_White.png";
import Right_Arrow_White from "../../../assets/icons/Right_Arrow_White.png";

export const NextButton = ({ style, onClick }) => {

    return(
        <div {...{onClick, style}} 
          className="nextbutton floatingnext">
          <img src={Right_Arrow_White} alt="next"/>
        </div>
    
    )
}

NextButton.propTypes = {
  style: PropTypes.any,
  onClick: PropTypes.func.isRequired
};

export const PrevButton = ({ style, onClick }) => {

  return(
      <div {...{onClick, style}} 
        className="prevbutton floatingprev">
        <img src={Left_Arrow_White} alt="prev"/>
      </div>
  
  )
}

PrevButton.propTypes = {
  style: PropTypes.any,
  onClick: PropTypes.func.isRequired
};
