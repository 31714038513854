import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import PropTypes from "prop-types";

// IMPORT COMPONENTS
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import {
  DefaultButton,
  InvertedButton,
} from "../../common/button/Button.common";
// IMPORT LOGOS
import { postVolunteers } from "../../services/getinvolved/volunteers.service";
//SERVICES
const VolunteerForm = () => {
  const { register, handleSubmit, errors } = useForm();
  const [suberror, setSuberror] = useState(false);
  const [loading, setloading] = useState(false);
  const [fileName, setFileName] = useState("");
  const onSubmit = async (data) => {
    setSuberror(false);
    setloading(true);
    let responseVolunteer = await postVolunteers(data);
    if (responseVolunteer && responseVolunteer.data.success === true) {
      toast.info("Succcess! Thanks for applying.");
      setloading(false);
    } else {
      toast.error("Failed! Please recheck your details or Try again Later");
      setloading(false);
    }
  };
  return (
    <div className="volunteerform-container">
      <div className="volunteerform">
        <form method="post" encType="multipart/form-data">
          <div className="volunteerform-form">
            <input
              className={`volunteerform-form-input volunteerform-form-fullname text-hairline ${
                suberror === true || errors.fullname ? "showerror" : ""
              }`}
              name="fullname"
              ref={register({ required: true })}
              placeholder="Full Name"
              required
            />
            <input
              className={`volunteerform-form-input volunteerform-form-contact" ${
                suberror === true || errors.contact ? "showerror" : ""
              }`}
              name="contact"
              type="number"
              ref={register({ required: true })}
              placeholder="Ph. Number"
            />
            <input
              className={`volunteerform-form-input volunteerform-form-email ${
                suberror === true || errors.email ? "showerror" : ""
              }`}
              name="email"
              ref={register({ required: true })}
              placeholder="Email"
            />
            <textarea
              className={`volunteerform-form-input volunteerform-form-contribution ${
                suberror === true || errors.contribution ? "showerror" : ""
              }`}
              name="contribution"
              ref={register({ required: true })}
              placeholder="What contribution can you make to society through ECDC?"
            />
            <textarea
              className={`volunteerform-form-input volunteerform-form-experience ${
                suberror === true || errors.experience ? "showerror" : ""
              }`}
              name="experience"
              ref={register({ required: true })}
              placeholder="Do you have any experience working with children before?"
            />
            <textarea
              className={`volunteerform-form-input volunteerform-form-explain ${
                suberror === true || errors.expertize ? "showerror" : ""
              }`}
              rows="5"
              name="expertize"
              ref={register({ required: true })}
              placeholder="Please explain your expertise you plan on utilizing/shareing with the children to contribute to their education, whether academic, extracurricular or otherwise ?"
            />
          </div>

          <div className="volunteerform-upload">
            <CompWrapper
              style={{ paddingTop: 40 }}
              title="Upload your CV"
              highlightText="CV"
            >
              <div className="volunteerform-upload-content">
                <input
                  type="file"
                  name="cv"
                  accept="application/pdf"
                  ref={register({ required: true })}
                  onChange={(event) =>
                    setFileName(event && event.target.files[0].name)
                  }
                />
                <DefaultButton
                  title="open file"
                  onClick={(event) => event.preventDefault()}
                />
              </div>
              <div className="text-black text-font-averta mt-4">{fileName}</div>

              <div className="volunteerform-upload-divider" />
              <div className="text-black text-font-averta mb-5">
                * You should work at least 3 month.
              </div>
              <div className="volunteerform-upload-join">
                <InvertedButton
                  style={{ margin: "0px auto" }}
                  title={loading ? "JOINING..." : "JOIN NOW"}
                  // type="submit"
                  onClick={
                    loading ? (e) => e.preventDefault() : handleSubmit(onSubmit)
                  }
                />
              </div>
            </CompWrapper>
          </div>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default VolunteerForm;
