import React from "react";
import PropTypes from "prop-types";

// IMPORT ICONS
import ECDC_Twodash from "../../../assets/icons/ECDC_twodash.svg";

const Timeline = ({ data }) => {
  return (
    <div className="timeline-container">
      <div className="timeline">

        {
          
          data.length ?
          data.map(({ date, dateHighlight, title, highlightText, description, image }, index) => {
            
            if(title.indexOf(highlightText) < 0) {
              console.error("NO TEXT HIGHLIGHT PART");
            }

            if(date.indexOf(dateHighlight) < 0) {
              console.error("NO DATE HIGHLIGHT PART");
            }
          
            let titleWithoutHighlight = title.slice(0, title.indexOf(highlightText));
            let titleWithHighlight = title.slice(title.indexOf(highlightText), title.length);
            
            let dateWithoutHighlight = date.slice(0, date.indexOf(dateHighlight));
            let dateWithHighlight = date.slice(date.indexOf(dateHighlight), date.length);

            return (
              <div key={index} className="timeline-event">
                <div className="timeline-event-left">
                  <div className="event-date text-font-rumpel text-upper text-xsm">
                    { dateWithoutHighlight } <span className="text-blue text-font-rumpel">{ dateWithHighlight }</span>
                  </div>

                  <div className="timeline-event-indicator"></div>
                </div>

                <div className="timeline-event-right">
                  <div className="event-data">
                    <div className="event-data-header text-font-rumpel text-upper text-xsm">
                      { titleWithoutHighlight } <span className="text-blue text-font-rumpel">{ titleWithHighlight}</span>
                    </div>
                    <div className="event-data-image-container">
                      <img alt="TimelinePhoto" src={image} />
                    </div>
                    <div className="event-data-desc">
                      { description }
                    </div>

                    <div className="event-data-twodash">
                      <img alt="TWO DASH" src={ECDC_Twodash} />
                    </div>
                  </div>
                </div>
              </div>
            )
          }) 
          : 
          <div className="no-results">
            No data available
          </div>
        }

      </div>
    </div>
  )
}

Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      dateHighlight: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      highlightText: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.node.isRequired
    })
  ).isRequired
};

Timeline.defaultProps = {
  data: [
    { date: "JAN 1, 2000", dateHighlight: "2000", title: "DEFAULT TITLE", highlightText: "TITLE", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus venenatis dui mi, ut aliquet dolor dignissim a. Pellentesque sollicitudin id felis molestie feugiat. Sed malesuada sollicitudin risus, at sagittis nisi interdum id. Sed tempor magna in ornare mollis. Quisque auctor laoreet dolor sed semper. Proin cursus pellentesque nibh, quis tristique odio dignissim id." },
    { date: "JAN 1, 2000", dateHighlight: "2000", title: "DEFAULT TITLE", highlightText: "TITLE", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus venenatis dui mi, ut aliquet dolor dignissim a. Pellentesque sollicitudin id felis molestie feugiat. Sed malesuada sollicitudin risus, at sagittis nisi interdum id. Sed tempor magna in ornare mollis. Quisque auctor laoreet dolor sed semper. Proin cursus pellentesque nibh, quis tristique odio dignissim id." }
  ]
};

export default Timeline;