import React, { useState } from "react";
import { useForm } from "react-hook-form";

// IMPORT COMPONENTS
import { InvertedButton } from "../../common/button/Button.common";

//IMPORT SERVICES
import { buyecommerce } from "../../services/products/products";

//SERVICES
const Quote = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [suberror, setSuberror] = useState(false);
  const [loading, setloading] = useState(false);
  const onSubmit = async (data) => {
    setSuberror(false);
    setloading(true);
    let responseEcommerce = await buyecommerce(
      data,
      props.productDetails.product_name
    );
    //    console.log('Resonponse', responseEcommerce)
    if (responseEcommerce && responseEcommerce.data.success === true) {
      window.document.location.reload();
      setloading(false);
    } else {
      setSuberror(true);
      setloading(false);
    }
  };
  return (
    <div className="quote-container">
      <div className="quote">
        <form method="post" encType="multipart/form-data">
          <div
            className="quote-form"
            style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 20 }}
          >
            <input
              className={`quote-form-input text-hairline ${
                suberror === true || errors.fullname ? "showerror" : ""
              }`}
              name="fullname"
              ref={register({ required: true })}
              placeholder="Full Name"
              required
            />
            <input
              className={`quote-form-input " ${
                suberror === true || errors.quantity ? "showerror" : ""
              }`}
              name="quantity"
              type="number"
              ref={register({ required: true })}
              placeholder="Number of items"
            />
            <input
              className={`quote-form-input " ${
                suberror === true || errors.location ? "showerror" : ""
              }`}
              name="location"
              ref={register({ required: true })}
              placeholder="Location"
            />
            <input
              className={`quote-form-input ${
                suberror === true || errors.email ? "showerror" : ""
              }`}
              name="email"
              ref={register({ required: true })}
              placeholder="Email"
            />
            <textarea
              className={`quote-form-input  ${
                suberror === true || errors.message ? "showerror" : ""
              }`}
              name="message"
              ref={register({ required: true })}
              placeholder="Message"
            />
            <div className="quote-upload-join">
              <InvertedButton
                style={{ margin: "0px auto" }}
                title={loading ? "REQUESTING" : "REQUEST A QUOTE"}
                // type="submit"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Quote;
