import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";

// IMPORT LOGOS
import TwoDash_gray from "../../../assets/icons/Twodash_gray.svg";
import ChildInsured from "../../../assets/icons/ChildInsured_purple.png";
import Box_green from "../../../assets/icons/Box_green.png";
import Nutrients_1_orange from "../../../assets/icons/Nutrients_1_orange.png";
import Babybox_teal from "../../../assets/icons/Babybox_teal.png";

const pradesh1=[
    "Taplejung",
    "Illam",
    "Panchthar",
    "Jhapa",
    "Terhathum",
    "Dhankuta",
    "Sankhuwasabha",
    "Morang",
    "Bhojpur",
    "Sunsari",
    "Khotang",
    "Udayapur",
    "Solukhumbu",
    "Okhaldhunga"
]

const pradesh2=[
    "Saptari",
    "Siraha",
    "Dhanusa",
    "Mahottari",
    "Sarlahi",
    "Rauthat",
    "Bara",
    "Parsa",
]

const pradesh3=[
    "Chitwan",
    "Makwanpur",
    "Dhading",
    "Nuwakot",
    "Rasuwa",
    "Kathmandu",
    "Lalitpur",
    "Bhaktapur",
    "Sindhupalchowk",
    "Kavrepalanchowk",
    "Dolkha",
    "Sindhuli",
    "Ramechhap",
]

const pradesh4=[
    "Gorkha",
    "Tanahun",
    "Nawalparasieast",
    "Lamjung",
    "Syangja",
    "Manang",
    "Kaski",
    "Parbat",
    "Mustang",
    "Myagdi",
    "Baglung",
]

const pradesh5=[
    "Parasi",
    "Rupandehi",
    "Palpa",
    "Gulmi",
    "Kapilvastu",
    "Arghakhanchi",
    "Pyuthan",
    "Rolpa",
    "Dang",
    "Rukumeast",
    "Bardiya",
    "Banke",
]

const pradesh6=[
    "Rukumwest",
    "Dolpa",
    "Salyan",
    "Jajarkot",
    "Dailekh",
    "Surkhet",
    "Jumla",
    "Humla",
    "Kalikot",
]

const pradesh7=[
    "Achham",
    "Bajura",
    "Bajhang",
    "Doti",
    "Kailali",
    "Darchula",
    "Kanchanpur",
    "Dadeldhura",
    "Baitadi",
]

const MapDataCard = ({ name, mouseXY,data }) => {
    const[butterfly,setButterfly]=useState(null);
    const[kit,setKit]=useState(null);
    const[diet,setDiet]=useState(null);
    const[insurance,setInsurance]=useState(null);
    const[districtName,setDistrict]=useState(name);
    const[assign,setAssign] = useState(true);
    const[colorPradesh,setColorPradesh] = useState("red");
    useEffect(() => {
        if(districtName !== name){
                setDistrict(name);
                setAssign(true);
                setButterfly(null);
                setInsurance(null);
                setKit(null);
                setDiet(null);
            if(assign){
                data && data.map((item,index)=>{
                    switch(item.programme_id){
                        case 0:
                            return setButterfly(item);
                        case 1:
                            return setInsurance(item);
                        case 2:
                            return setKit(item);
                        case 3:
                            return setDiet(item);
                        default:
                            return ''
                    }
                })
            }
        }
        //   return () =>{
        //     setAssign(false);
        //   }
      }, [districtName, assign, name, data]);
   
      useEffect(()=>{
        if(pradesh1.includes(name)){
            setColorPradesh("#327fd1")
        }
        if(pradesh2.includes(name)){
            setColorPradesh("#986fbf")
        }
        if(pradesh3.includes(name)){
            setColorPradesh("#ff7033")
        }
        if(pradesh4.includes(name)){
            setColorPradesh("#35a67e")
        }
        if(pradesh5.includes(name)){
            setColorPradesh("#17a2b8")
        }
        if(pradesh6.includes(name)){
            setColorPradesh("#dc3545")
        }
        if(pradesh7.includes(name)){
            setColorPradesh("#28a745")
        }       
      },[name])

    const { x, y } = mouseXY;

    const cliff = window.innerWidth / 2;

    const transformXY = {
        transformLeft: 'translate(-10%, -80%)',
        transformRight: 'translate(-130%, -100%)'
    };

    let transform = x < cliff ? transformXY['transformLeft'] : transformXY['transformRight'];

    const cardContainerStyle = {
        left: x, 
        top: y,
        transform
    };

    return (
        <div className="mapdatacard-container" style={cardContainerStyle}>
            <div className="mapdatacard pt-5 pl-5 pr-5 pb-5">
                <div className="mapdatacard-district text-sm text-font-rumpel text-upper" style={{color:colorPradesh}}>
                    {name}
                    <div className="mapdatacard-district-dash" style={{
                        width: "40px",
                        height: "6px",
                        background:colorPradesh,
                        borderRadius: "20px"}}/>
                </div>
                <div className="mapdatacard-body">
                    <div className="mapdatacard-body-insurance pt-5">
                        <div className="mapdatacard-body-insurance-title">
                            <div className="mapdatacard-body-insurance-title-icon">
                                <img src={ChildInsured} alt="icon" />
                            </div>
                            <div className="mapdatacard-body-insurance-title-text text-thin text-font-rumpel text-purple ml-3 text-upper">
                                insurance
                        </div>
                        </div>

                        <div className="mapdatacard-body-insurance-data">
                            {/* <div className="text-xthin text-font-rumpel text-black text-upper">
                                No. of child : <span className="text-xthin text-font-rumpel text-purple text-upper">
                                    {" "}{insurance?insurance.total_child?insurance.total_child:"Data not available":"Data not available"}</span>
                            </div> */}
                            <div className="text-xthin text-font-rumpel text-black text-upper">
                                Gender : <span className="text-xthin text-font-rumpel text-purple text-upper">{" "}{insurance?insurance.male?insurance.male + ' Boy':null:"Data not available"}{" "}{insurance?insurance.female?insurance.female + ' Girl':null:null}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mapdatacard-body-nursery pt-5">
                        <div className="mapdatacard-body-nursery-title">
                            <div className="mapdatacard-body-nursery-title-icon">
                                <img src={Box_green} alt="icon" />
                            </div>
                            <div className="mapdatacard-body-nursery-title-text text-thin text-font-rumpel text-green ml-3 text-upper">
                                nursery kit
                        </div>
                        </div>

                        <div className="mapdatacard-body-nursery-data">
                            <div className="text-xthin text-font-rumpel text-black text-upper">
                                No. of beneficiary : <span className="text-xthin text-font-rumpel text-green text-upper">{" "}{kit?kit.beneficiary_num?kit.beneficiary_num:"Data not available":"Data not available"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mapdatacard-body-nutrition pt-5">
                        <div className="mapdatacard-body-nutrition-title">
                            <div className="mapdatacard-body-nutrition-title-icon">
                                <img src={Nutrients_1_orange} alt="icon" />
                            </div>
                            <div className="mapdatacard-body-nutrition-title-text text-thin text-font-rumpel text-orange ml-3 text-upper">
                                nutrition diet
                        </div>
                        </div>

                        <div className="mapdatacard-body-nutrition-data">
                            <div className="text-xthin text-font-rumpel text-black text-upper">
                                Total meal served : <span className="text-xthin text-font-rumpel text-orange text-upper">{" "}{diet?diet.total_meal_served?diet.total_meal_served:"Data not available":"Data not available"}</span>
                            </div>
                            {/* <div className="text-xthin text-font-rumpel text-black text-upper">
                                Total Child : <span className="text-xthin text-font-rumpel text-orange text-upper">{" "}{diet?diet.total_child?diet.total_child:"Data not available":"Data not available"}</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="mapdatacard-body-butterfly pt-5">
                        <div className="mapdatacard-body-butterfly-title">
                            <div className="mapdatacard-body-butterfly-title-icon">
                                <img src={Babybox_teal} alt="icon" />
                            </div>
                            <div className="mapdatacard-body-butterfly-title-text text-thin text-font-rumpel text-blue ml-3 text-upper">
                                butterfly baby box
                        </div>
                        </div>
                        <div className="mapdatacard-body-butterfly-data">
                            <div className="text-xthin text-font-rumpel text-black text-upper">
                                date of distribution : <span className="text-xthin text-font-rumpel text-blue text-upper">{" "}{butterfly?butterfly.distribution_date?butterfly.distribution_date:null:"Data not available"}</span>
                            </div>
                            <div className="text-xthin text-font-rumpel text-black text-upper">
                                Gender : <span className="text-xthin text-font-rumpel text-blue text-upper">{" "}{butterfly?butterfly.male?butterfly.male + ' Boy':null:"Data not available"}{" "}{butterfly?butterfly.female?butterfly.female + ' Girl':null:null}</span>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="mapdatacard-twodash">
                    <img alt="FLOATING TWO DASH" src={TwoDash_gray} />
                </div>
            </div>
        </div>
    )
}

MapDataCard.propTypes = {
    name: PropTypes.string.isRequired,
    mouseXY: PropTypes.object
};

MapDataCard.defaultProps = {
    mouseXY: {x: 0, y: 0}
};

export default MapDataCard