import React from "react";
import PropTypes from "prop-types";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import {FloatButton} from "../../common/button/Button.common"
// eslint-disable-next-line react/prop-types
const NecessaryItems = ({titlehead,things}) => {
    return (
        <CompWrapper style={{paddingTop: 0,marginTop:60}} title={titlehead} highlightText="">
            <div className="necessaryitems-container" style={{paddingTop:30}}>
                <div className="necessaryitems">
                    {things.map((item)=> (<FloatButton style={{flexWrap: "wrap",margin:"15px 15px"}} title={item} highlightText={item} highlightColor="#FF8144"/>))}
                    {/* <FloatButton title="itefsdfsdm one" highlightText="itefsdfsdm one" highlightColor="#FF8144"/>
                    <FloatButton title="item one" highlightText="item one" highlightColor="#FF8144"/>
                    <FloatButton title="item one" highlightText="item one" highlightColor="#FF8144"/>
                    <FloatButton title="item one" highlightText="item one" highlightColor="#FF8144"/>
                    <FloatButton title="item one" highlightText="item one" highlightColor="#FF8144"/>
                    <FloatButton title="item one" highlightText="item one" highlightColor="#FF8144"/> */}
                </div>
            </div>
        </CompWrapper>
        )
    }


NecessaryItems.prototype ={
    titlehead: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    things:PropTypes.array.isRequired
}

NecessaryItems.defaultProps = {
    titlehead: "Items to Bring",
    highlightText: "Bring",
}

export default NecessaryItems;