import React from "react";

// IMPORT COMPONENTS
import ItemsToBring from "./itemsToBring/ItemsToBring.component"
import NecessaryItems from "./necessaryItems/necessaryItems.component"
//IMPORT COMMONS

const SHOES=["Rainboots","Vests","Sweaters","Socks and underwear","Jackets","Woolen Cap","Grey Muffler"];
const GAMES=["Paddle Ball set","Pens and Pencils","Erasers","Puzzles","Card decks and Card Games","Checkers","Chalk","Colored Paper","Building games (Leggo, Lincoln logs, etc…)","Boardgames","DVDs of fun kids movies","Kites"];
const MEDICAL=["Band-Aids","Betadine","Sterile gauze and cotton","Cream lotion","Vitamins","Calcium","Baby Lotion"];
const OTHER=["Backpacks","Reusable Water Bottles","Umbrella"];
const INMATES =["Reading Glasses"];
const ItemsToBringPage = () => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <ItemsToBring />
            <NecessaryItems titlehead="SHOES AND CLOTHING FOR KIDS (SIZE/AGE 3-14 YEARS OLD)" things={SHOES}/>
            <NecessaryItems titlehead="GAMES FOR CHILDREN" things={GAMES}/>
            <NecessaryItems titlehead="MEDICAL SUPPLIES" things={MEDICAL}/>
            <NecessaryItems titlehead="OTHER" things={OTHER}/>
            <NecessaryItems titlehead="INMATES" things={INMATES}/>
        </React.Fragment>
    )
}

export default ItemsToBringPage;