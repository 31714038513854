import React from 'react';
import { Map as MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';

const Map = ({ location }) => {
  return (
    <MapContainer
      center={location}
      zoom={14}
      scrollWheelZoom={false}
      style={{ height: 400, width: '100%', zIndex: 0 }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={location}>
        {/* sdfksdfh */}
        <Popup>ECDC (Butterfly House)</Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
