import React from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";


// IMPORT COMPONENTS
import SupportCard from "../../common/supportCard/supportCard.common";

//IMPORT ICONS
import Donate_teal from "../../../assets/icons/Donate_teal.png";
import Volunteer_orange from "../../../assets/icons/Volunteer_orange.png";
import Sponsor_purple from "../../../assets/icons/Sponsor_purple.png";

const SponsorCardSection = (props) => {
    return (
        <CompWrapper style={{ marginTop: 100 }} title="BECOME A SPONSOR" highlightText="SPONSOR">
            <div className="sponsorcardsection-container">
                <div className="sponsorcardsection">
                    <div className="sponsorcardsection-desc text-font-averta text-thin">
                        One of the best ways to get involved with ECDC is to become a sponsor. We take sponsorship donations seriously, which means the causes that you request to sponsor really are effected directly by your donation.  With our Sponsor A Child program, you are connected to a specific child for a year, to share in their progress that is made possible by you!
                    </div>
                    <div className="sponsorcardsection-card">
                        <SupportCard
                            icon={Donate_teal}
                            defaultColor="#3180A9"
                            title="BUTTERFLY RESIDENTIAL HOME"
                            highlightText="HOME"
                            description="ECDC has been bringing in the children outside from the prison and providing them residential home"
                            buttonText="$400"
                            inverted={true}
                            style={{ width: 100 }} />
                        <SupportCard
                            icon={Volunteer_orange}
                            defaultColor="#FF8144"
                            title="REINTEGRATION SUPPORT"
                            highlightText="SUPPORT"
                            description="Support reintegrated children in education - new pair of school uniform, backpack school fees and so on"
                            buttonText="$350"
                            inverted={true}
                            style={{ width: 35 }} />
                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#A259F4"
                            title="MONTHLY FOOD SUPPORT"
                            highlightText="SUPPORT"
                            description="Providing children with the healthy meal for the better growth is one of our major objective."
                            buttonText="$200"
                            inverted={true}
                            style={{ width: 60 }} />
                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#FF8144"
                            title="SUMMER SWIMMING CLASS"
                            highlightText="CLASS"
                            description="We would prefer taking our children to swimming for both fitness and recreational purpose."
                            buttonText="$120"
                            inverted={true}
                            style={{ width: 60 }} />
                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#A259F4"
                            title="FUTSAL"
                            highlightText="FUTSAL"
                            description="Supporting children with sports, along side leadership and discipline is one of our objective for the development of children."
                            buttonText="$25"
                            inverted={true}
                            style={{ width: 60 }} />
                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#3180A9"
                            title="EDUCATIONAL TOUR FOR CHILDREN"
                            highlightText="CHILDREN"
                            description="We would prefer if our children are taken for the educational tours and excursion for their knowledge."
                            buttonText="$350"
                            inverted={true}
                            style={{ width: 60 }} />

                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#A259F4"
                            title="GYMNASTICS CLASS"
                            highlightText="CLASS"
                            description="We would prefer taking our children to swimming for both fitness and recreational purpose."
                            buttonText="$15"
                            inverted={true}
                            style={{ width: 60 }} />

                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#3180A9"
                            title="CARETAKER/MOTHER"
                            highlightText="CARETAKER/MOTHER"
                            description="As we have children of different age, we will need number of caretakers. We would appreciate if you would sponsor us for the caretakers."
                            buttonText="$750"
                            inverted={true}
                            style={{ width: 60 }} />

                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#FF8144"
                            title="FEMALE INMATES SUPPORT"
                            highlightText="SUPPORT"
                            description="Supporting the female inmates after they graduate is necessary for their sustainable livelihood. Therefore, funds for the small scale business set up, or any kind of other income generating activities such as sewing training, beautician training is appreciated."
                            buttonText="$200"
                            inverted={true}
                            style={{ width: 60 }} />

                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#FF8144"
                            title="DRIVING CLASS"
                            highlightText="CLASS"
                            description="Driving classes for the adult is also one of the ways to sponsor. Driving will benefit them as the source of income."
                            buttonText="$200"
                            inverted={true}
                            style={{ width: 60 }} />

                        <SupportCard
                            icon={Sponsor_purple}
                            defaultColor="#A259F4"
                            title="BAKERY CLASS"
                            highlightText="CLASS"
                            description="Providing trainings for bakery class and cooking will support the inmates in skill development, also in the long run they will be able to run or work in the hospitality sector."
                            buttonText="$200"
                            inverted={true}
                            style={{ width: 60 }} />
                        
                    </div>

                </div>
            </div>
        </CompWrapper>
    )
}

export default SponsorCardSection;