import React from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";

// IMPORT COMMONS
import AchievementCard from "../../common/achievementCard/AchievementCard.common";

// IMPORT ICONS
import Phone from "../../../assets/icons/Phone.png";
import Location from "../../../assets/icons/Location.png";
import Email from "../../../assets/icons/Email.png";

import Twodash_orange from "../../../assets/icons/Twodash_orange.svg";
import Twodash_green from "../../../assets/icons/Twodash_green.svg";
import Twodash_purple from "../../../assets/icons/Twodash_purple.svg";

const ContactSection = (props) => {
  return (
    <CompWrapper title="CONTACT US" highlightText="US">
      <div className="morewaystodonate-container">
        <div className="morewaystodonate">
          <div className="morewaystodonate-desc text-font-averta text-thin">
            We'd love to hear from you!
          </div>
          <div className="morewaystodonate-card">
            <div data-aos="fade-up">
              <AchievementCard
                icon={Phone}
                defaultColor="#FF8144"
                dash={Twodash_orange}
                highlightText="PHONE"
                buttonText="MORE&nbsp;DETAILS"
                contactDetails="+977-9851101747"
                contactCard={true}
              />
            </div>
            <div data-aos="fade-up" data-aos-delay={100}>
              <AchievementCard
                icon={Location}
                defaultColor="#5B9540"
                dash={Twodash_green}
                highlightText="LOCATION"
                buttonText="MORE&nbsp;DETAILS"
                contactDetails="Butterfly Home, Budhanilkantha, Kathmandu, Nepal"
                contactCard={true}
              />
            </div>
            <div data-aos="fade-up" data-aos-delay={200}>
              <AchievementCard
                icon={Email}
                defaultColor="#A259F4"
                dash={Twodash_purple}
                highlightText="EMAIL"
                buttonText="MORE&nbsp;DETAILS"
                contactDetails="pushpabasnet@ecdcnepal.org&#010;info@ecdcnepal.org"
                contactCard={true}
              />
            </div>
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default ContactSection;
