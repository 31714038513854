import React from "react";

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";



const WishListDonate = (props) => {
    return (
        <CompWrapper style={{marginTop: 100}} title="WISHLIST" highlightText="WISHLIST">
            <div className="wishlistdonate-container">
                <div className="wishlistdonate">
                    <div className="wishlistdonate-desc text-font-averta text-thin">
                    Our wishlist items are things that we would love to have, but can’t prioritize over other expenses at the moment.  When you donate towards a wishlist item, we will make sure to honor the intention of that donation.  
                    </div>
                    {/* <div>

                        <div className="wishlistdonate-card">
                            <SupportCard 
                                icon={Donate_teal} 
                                defaultColor="#3180A9" 
                                title="BUTTERFLY RESIDENTIAL HOME" 
                                highlightText="HOME" 
                                description="ECDC has been bringing in the children outside from the prison and providing them residential home" 
                                buttonText="$400" 
                                inverted={true}
                                style={{width: 100}} />
                            <SupportCard 
                                icon={Volunteer_orange} 
                                defaultColor="#FF8144" 
                                title="REINTEGRATION SUPPORT" 
                                highlightText="SUPPORT" 
                                description="Support reintegrated children in education - new pair of school uniform, backpack school fees and so on" 
                                buttonText="$350" 
                                inverted={true}
                                style={{width: 35}} />
                            <SupportCard 
                                icon={Sponsor_purple} 
                                defaultColor="#A259F4" 
                                title="MONTHLY FOOD SUPPORT" 
                                highlightText="SUPPORT" 
                                description="Providing children with the healthy meal for the better growth is one of our major objective." 
                                buttonText="$200" 
                                inverted={true}
                                style={{width: 60}} />
                            <SupportCard 
                                icon={Sponsor_purple} 
                                defaultColor="#3180A9" 
                                title="SUMMER SWIMMING CLASS" 
                                highlightText="CLASS" 
                                description="We would prefer taking our children to swimming for both fitness and recreational purpose." 
                                buttonText="$120" 
                                inverted={true}
                                style={{width: 60}} />
                            <SupportCard 
                                icon={Sponsor_purple} 
                                defaultColor="#FF8144" 
                                title="FUTSAL" 
                                highlightText="FUTSAL" 
                                description="Supporting children with sports, along side leadership and discipline is one of our objective for the development of children." 
                                buttonText="$25" 
                                inverted={true}
                                style={{width: 60}} />

                        </div>
                    </div>                     */}
                </div>
            </div>
        </CompWrapper>
    )
}

export default WishListDonate;