import React from "react";

// IMPORT COMPONENTS
import VolunteerContent from "./volunteerContent/VolunteerContent.component"
// IMPORT COMMONS
import {OverlappedCircle,SubCircleVolunteer} from "../common/floatingImage/Circle.common";

const BecomeVolunteerPage = () => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <VolunteerContent/>
            <SubCircleVolunteer />
            <OverlappedCircle/>
        </React.Fragment>
    )
}

export default BecomeVolunteerPage;