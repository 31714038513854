import React,{ useState, useEffect } from "react";

// IMPORT COMMON
import DetailCard from "../../common/detailCard/DetailCard.common";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

import DetailCardPlaceholder from "../../../assets/placeholder/DetailCardPlaceholder.svg"

//SERVICES
import {getStories} from "../../services/detailpage/storydetail.service"
const DetailCardSection = (props) => {
    const [stories,setStories] = useState(null);
    const [image,setImage] = useState(null)
    const [animating,setAnimating] = useState(true);
    const [callApi,setApiCall] = useState(true);
    useEffect(() => {
        if(callApi){
          (async()=> {
            let storiesDetail = await getStories(props.match.params.id);
            if(storiesDetail && storiesDetail.data.success){
              setApiCall(false);
              setStories(storiesDetail.data.data);
              setImage(storiesDetail.data.image)
              setAnimating(false)
            }
          })();
        }
          return () =>{
            setApiCall(false);
          }
      });
    return (
            <div className="detailcardsection-container">
                <div className="detailcardsection">
                   <Placeholder placeholderImage={DetailCardPlaceholder} placeholderImageMob={DetailCardPlaceholder} placeholderImageTab={DetailCardPlaceholder} animating={animating} style={{ marginTop: 60,marginRight:"7.5%" }}>
                    {stories &&
                     <DetailCard
                          // showPlaceholder={animating}
                          title={stories[0].title}
                          highlightText={stories[0].title.split(" ").slice(-1)}
                          description={stories[0].description}
                          images={image}
                          dateVisible={stories[0].created_at}
                      />}
                      </Placeholder>
                </div>
            </div>
    )
}

export default DetailCardSection;