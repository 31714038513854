import React from "react";


// IMPORT COMPONENTS
import SuccessStories from "./successStories/SuccessStories.component";
import OurWork from "./ourWork/OurWork.component";
import PushpaJourney from "./pushpaJourney/PushpaJourney.component"
import Stories from "./stories/Stories.component";
import OurAchievement from "../common/ourAchievement/OurAchievement.component";
import {OverlappedCircle} from "../common/floatingImage/Circle.common";


const HomePage = (props) => {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
          <SuccessStories {...props} />
          <OurWork {...props}/>
          <PushpaJourney {...props}/>
          <Stories {...props} />
          <OurAchievement {...props}/>
          <OverlappedCircle/>
          {/* <ScrollToTop/> */}
        </React.Fragment>
    )
}
export default HomePage;