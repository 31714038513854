import React, { useEffect } from "react";
import { useForm } from 'react-hook-form'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";


// IMPORT COMMONS
import AchievementCard from "../../common/achievementCard/AchievementCard.common";

import { postfundRaiser } from "../../services/getinvolved/fundraiser.service"

// IMPORT ICONS 
import Wishlist_orange from "../../../assets/icons/Wishlist_orange.png";
import ThingsToBring_green from "../../../assets/icons/ThingsToBring_green.png";
import Fundraiser_purple from "../../../assets/icons/Fundraiser_purple.png";

import Twodash_orange from "../../../assets/icons/Twodash_orange.svg";
import Twodash_green from "../../../assets/icons/Twodash_green.svg";
import Twodash_purple from "../../../assets/icons/Twodash_purple.svg";


const MoreWaysToDonate = (props) => {
  const { register, handleSubmit, errors } = useForm()
  const [open, setOpen] = React.useState(false);
  const [suberror, setSuberror] = React.useState(false);
  const [screenWidth, setScreenWidth] = React.useState();

  
  const widthUpdate = () => {
    // console.log("width",window.innerWidth)
    setScreenWidth(window.innerWidth)
  };
  window.addEventListener("resize", widthUpdate);

  useEffect(()=>{
    widthUpdate()
  },[])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    setSuberror(false);
    let fundraiser = await postfundRaiser(data)
    if (fundraiser?.data?.success === true) {
      handleClose();
    }
    else {
      setSuberror(true);
    }
  }

  return (
    <CompWrapper title="MORE WAYS TO DONATE" highlightText="DONATE">
      <div className="morewaystodonate-container">
        <div className="morewaystodonate">
          <div className="morewaystodonate-desc text-font-averta text-thin">
            There are many other ways by which you can influence the lives of
            children. Explore the more ways to give away below.
            </div>
          <div className="morewaystodonate-card">
            <div
              data-aos="fade-up"
            >
              <AchievementCard
                icon={Wishlist_orange}
                defaultColor="#FF8144"
                dash={Twodash_orange}
                highlightText="WISH LIST"
                onButtonClick={() => props.history.push(`/get-involved/wishlist`)}
                buttonText="MORE&nbsp;DETAILS"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <AchievementCard
                icon={ThingsToBring_green}
                defaultColor="#5B9540"
                dash={Twodash_green}
                highlightText="THINGS TO BRING"
                onButtonClick={() => props.history.push(`/get-involved/items-to-bring`)}
                buttonText="MORE&nbsp;DETAILS"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay={200}
              className="fundRaiser"
            >
              <AchievementCard
                icon={Fundraiser_purple}
                defaultColor="#A259F4"
                dash={Twodash_purple}
                highlightText="START A FUNDRAISER"
                buttonText="MORE&nbsp;DETAILS"
                onButtonClick={handleClickOpen}
              />
              <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
                <DialogTitle ><div style={{display:"flex",alignItems:"center",justifyContent:'center', fontWeight:"bold"}}>START A FUNDRAISER</div></DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}  >
                  <DialogContent >
                    <div style={{
                      display:"grid",
                      gridRowGap:20,
                      width:screenWidth<576?"40vh":"60vh",
                      
                    }}>
                      <input
                        style={{
                          padding: screenWidth<768?(screenWidth<576? "15px 20px":"20px 25px"):"25px 30px",
                          width: "100%",
                          outline: "none",
                          borderRadius: 20,
                          boxShadow: "4px 6px 40px rgba(0,0,0,0.04)",
                          border: suberror === true || errors.name ?"1px solid $ecdc_red":"none",
                          background: suberror === true || errors.name ?"#FF434305":"none",
                        }}
                        name="name"
                        ref={register({ required: true })}
                        placeholder="Full Name"
                        required />
                      <input
                        style={{
                          padding: screenWidth<768?(screenWidth<576? "15px 20px":"20px 25px"):"25px 30px",
                          width: "100%",
                          outline: "none",
                          borderRadius: 20,
                          boxShadow: "4px 6px 40px rgba(0,0,0,0.04)",
                          border: suberror === true || errors.contact_number ?"1px solid $ecdc_red":"none",
                          background: suberror === true || errors.contact_number ?"#FF434305":"none",
                        }}
                        name="contact_number"
                        type="number"
                        ref={register({ required: true })}
                        placeholder="Ph. Number" 
                        required />
                      <input
                        style={{
                          padding: screenWidth<768?(screenWidth<576? "15px 20px":"20px 25px"):"25px 30px",
                          width: "100%",
                          outline: "none",
                          borderRadius: 20,
                          boxShadow: "4px 6px 40px rgba(0,0,0,0.04)",
                          border: suberror === true || errors.email ?"1px solid $ecdc_red":"none",
                          background: suberror === true || errors.email ?"#FF434305":"none",
                        }}
                        type="email"
                        name="email"
                        ref={register({ required: true })}
                        placeholder="Email" 
                        required />
                      <textarea
                        style={{
                          padding: screenWidth<768?(screenWidth<576? "15px 20px":"20px 25px"):"25px 30px",
                          width: "100%",
                          outline: "none",
                          borderRadius: 20,
                          boxShadow: "4px 6px 40px rgba(0,0,0,0.04)",
                          border: suberror === true || errors.description ?"1px solid $ecdc_red":"none",
                          background: suberror === true || errors.description ?"#FF434305":"none",
                        }}
                        name="description"
                        ref={register({ required: true })}
                        placeholder="Description"
                        required />
                    </div>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                      </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      >
                      Submit
                      </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </CompWrapper>
  );
}

export default MoreWaysToDonate;