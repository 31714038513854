import React from "react";

// IMPORT COMPONENTS
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc"
import VolunteerDescription from "../volunteerDescription/VolunteerDescription.component"
import VolunteerForm from "../volunteerForm/VolunteerForm.component"
// IMPORT COMMONS


const VolunteerContent = () => {
    return (
        <React.Fragment>
            <CompWrapper style={{marginTop: 100}} title="Become a volunteer" highlightText="volunteer" >
                <div className="volunteercontent-container">
                    <div className="volunteercontent">
                        <VolunteerDescription/>
                        <VolunteerForm/>
                    </div>
                </div>
            </CompWrapper>
        </React.Fragment>
    )
}

export default VolunteerContent;