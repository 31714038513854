/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';

// IMPORT HOCS
import Placeholder from '../../hoc/placeholder/Placeholder.hoc';
// IMPORT COMMON
import VideoSlider from '../../common/videoSlider/VideoSlider.component';

// IMPORT PLACEHOLDER IMAGE
import VideoPlaceholder from '../../../assets/placeholder/VideoPlaceholder.svg';

const ActivitiesCard = ({
  title,
  highlightText,
  description,
  images,
  animating,
}) => {
  if (title.indexOf(highlightText) < 0) {
    console.error('NO HIGHLIGHT PART');
  }
  let titleWithoutHighlight = title.slice(0, title.indexOf(highlightText));
  let titleWithHighlight = title.slice(
    title.indexOf(highlightText),
    title.length
  );

  const getLinkedDescription = (string) => {
    const isLink = (string) => {
      const reg =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

      return reg.test(string);
    };

    return string.split(' ').map((val, index) => {
      if (isLink(val)) {
        return (
          <a style={{ color: '#005592' }} href={val} key={index}>
            {val + ' '}
          </a>
        );
      }
      return <React.Fragment key={index}>{val + ' '}</React.Fragment>;
    });
  };

  return (
    <div className="activitiescard-container">
      <div className="activitiescard">
        <Placeholder
          placeholderImage={VideoPlaceholder}
          animating={animating}
          style={{
            marginTop: 60,
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div className="activitiescard-header">
            <div className="activitiescard-header-title text-font-rumpel text-upper text-lg">
              {titleWithoutHighlight}{' '}
              <span className="text-blue text-font-rumpel">
                {titleWithHighlight}
              </span>
            </div>
            <div className="activitiescard-header-desc text-thin mb-4">
              {getLinkedDescription(description)}
            </div>
            <VideoSlider sliderImages={images} />
          </div>
        </Placeholder>
      </div>
    </div>
  );
};

ActivitiesCard.propTypes = {
  title: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dateVisible: PropTypes.bool,
  images: PropTypes.node,
  animating: PropTypes.bool,
};

ActivitiesCard.defaultProps = {
  title: 'Default Title',
  highlightText: 'Title',
  description: 'Default Description',
  dateVisible: false,
};

export default ActivitiesCard;
