import React from 'react';

// IMPORT HOC
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";



const WorkInProcessPage = () => {
    return(
        <React.Fragment>
            <CompWrapper style={{marginTop: 100}} title="How children are brought to ECDC?" highlightText="ECDC?">
                <div className="workinprocess-container">
                    <div className="workinprocess">
                        <div className="workinprocess-desc text-font-averta text-thin">
                        ECDC has been providing residential home services to the children of inmates’ for the last 15 years. 
                        Among the children who are recues they have either been residing with their convicted parents inside 
                        the prison or have been living with their relatives due to their parents inside the prison. <br /> <br />
                        For children to be rescued and brought to ECDC - firstly we have to have the parents’ consent and willingness 
                        to place in a child care home. The parents write an application which the concerned prison verifies and collects 
                        all of the necessary documents such as child birth certificates and recommendations from the local governments.  
                        Once all of the paper works are done in coordination with the prison officials we rescue the child and bring them 
                        to our Butterfly Home. <br /> <br />
                        Till date we have rescued and provided residential home services to 175 inmates’ children (99 girls and 76 boys) from 
                        27 districts throughout the country. In Jan 2018, we performed our first international rescue where we rescued 1 Nepali 
                        Inmates’ girl child from Bahraich Jail, Uttar Pradesh – India in collaboration with Children Judicial Board UP – India. <br /> <br />
                        Among the 175 rescued we have already reintegrated 134 children and currently providing residential services to 41 children. 
                        </div>
                    </div>
                </div>
            </CompWrapper>
            <CompWrapper title="Outreach Projects" highlightText="Projects">
                <div className="workinprocess-container">
                    <div className="workinprocess">
                        <div className="workinprocess-desc text-font-averta text-thin">
                        Not all children in prison can come to the Butterfly Home, therefore from 2017 we started outreach programs to help babies, mothers 
                        and fathers that remain in prison and other inmates’ in the prison. ECDC has been implementing the outreach projects based on direct 
                        observation and upon request. <br /> <br />
                        While visiting the prison, we are directly exposed to the situation of children and inmates condition and facilities they are receiving 
                        inside the prison. Based on our observation we develop projects for addressing those needs. Some of the projects we directly developed 
                        and implemented are Butterfly Nursery Kit, Nutrition Diet, Butterfly Baby Box, Child Health Insurance. <br /> <br />
                        In other case projects are implemented inside the prison after receiving a request letter from inmates’ which has verified by the respected 
                        prison authorities for transparency and accountability. Upon receiving the request, we evaluate the need and implement accordingly such as books 
                        support for library, games and recreational items support, kitchen utensils support, support in implementation of Non-Formal Education inside prison.
                        </div>
                    </div>
                </div>
            </CompWrapper>
        </React.Fragment>
    )
}

export default WorkInProcessPage;