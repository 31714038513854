import React,{ useState, useEffect } from 'react';
import * as moment from 'moment';

// IMPORT HOC
import CompWrapper from "../../hoc/compWrapper/CompWrapper.hoc";
import Placeholder from "../../hoc/placeholder/Placeholder.hoc";

// IMPORT COMMONS
import DescriptionCard from "../../common/descriptionCard/DescriptionCard.common";
import { FloatButton } from "../../common/button/Button.common";

// IMPORT PLACEHOLDER IMAGE
import OurWork_Placeholder from "../../../assets/placeholder/OurWork.svg" 
import OurWork_Mobile from "../../../assets/placeholder/OurWork_Mobile.svg" 
import OurWork_Tablet from "../../../assets/placeholder/OurWork_Tablet.svg" 

//SERVICES
import {getStories} from '../../services/common/stories.service'

const Stories = (props) => {
  const [stories,setStories] = useState(null);
  const [animating,setAnimating] = useState(true);
  const [callApi,setApiCall] = useState(true);
  // const [params,setParams]=useState(null)
  useEffect(() => {
    if(callApi){
      (async()=> {
        let stories = await getStories();
        if(stories && stories.data.success){
          setApiCall(false);
          setStories(stories.data.data.data);
          setAnimating(false);
          // setParams(stories.data.data.data)
        }
      })();
    }
      return () =>{
        setApiCall(false);
      }
  });
    return (
      <CompWrapper title="STORIES" highlightText="STORIES" seeAllButtonVisible={true} onSeeAllClick={()=>props.history.push(`/stories`) }>
        <div className="stories-container">
          <Placeholder placeholderImage={OurWork_Placeholder} placeholderImageMob={OurWork_Mobile} placeholderImageTab={OurWork_Tablet} animating={animating}>
            <div className="stories">
                {
                  stories && stories.slice(0, 3).map((item, index) => (
                  <div
                    key={index}
                    data-aos="fade-up"
                    data-aos-delay={index * 100}
                  >
                      <DescriptionCard
                        title={item.title}
                        highlightText={item.title.split(" ").slice(-1)}
                        description={item.shortDescription}
                        source={item.thumbnail}
                        date={<FloatButton title= {moment(item.created_at).format('MMM DD, YYYY')} highlightText={item.created_at.slice(0,3)} />} 
                        onButtonClick={()=>window.open(`/detail-page/${item.id}-${item.slug_url}`,"_self") }
                      />
                  </div>
                  ))
                }
            </div>
          </Placeholder>
        </div>
      </CompWrapper>
    )
}

export default Stories;