import React from "react";
import PropTypes from "prop-types";

const CompWrapper = ({ children, title, highlightText, seeAllButtonVisible, onSeeAllClick, style }) => {

  if(title.indexOf(highlightText) < 0) {
    console.error("NO HIGHLIGHT PART");
  }

  let titleWithoutHighlight = title.slice(0, title.indexOf(highlightText));
  let titleWithHighlight = title.slice(title.indexOf(highlightText), title.length);

  return (
    <div {...{style}} className="compwrapper-container">
      <div className="compwrapper">
        <div className="compwrapper-header">
          <div className="compwrapper-header-title text-font-rumpel text-upper text-md">
            { titleWithoutHighlight } <span className="text-blue text-font-rumpel">{ titleWithHighlight }</span>
          </div>
          
          {
            seeAllButtonVisible
            &&
            <div onClick={onSeeAllClick} className="compwrapper-header-seemore text-font-rumpel text-md">
              See All
              <div className="seemore-dotone"></div>
              <div className="seemore-dottwo"></div>
            </div>
          }
          
        </div>

        { children }
      </div>
    </div>
  )
}

CompWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  highlightText: PropTypes.string,
  seeAllButtonVisible: PropTypes.bool,
  onSeeAllClick: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

CompWrapper.defaultProps = {
  title: "Default Title",
  highlightText: "Title",
  seeAllButtonVisible: false,
  onSeeAllClick: () => false
};

export default CompWrapper;